import { FunctionComponent, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MenuPS from "../components/MenuPS";
import PortalPopup from "../components/PortalPopup";
import styles from "./TermsAndConditions.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const TermsAndConditions: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isMenuPSOpen, setMenuPSOpen] = useState(false);

  const onButtonClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const openMenuPS = useCallback(() => {
    setMenuPSOpen(true);
  }, []);

  const closeMenuPS = useCallback(() => {
    setMenuPSOpen(false);
  }, []);

  const onHomeContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHistoryTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer2']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onValuesTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer1']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onOurTeamTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onOurProcessContainerClick = useCallback(() => {
    navigate("/terms-and-conditions");
  }, [navigate]);

  const onContactUsContainerClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onPrivacyPolicyTextClick = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onTermsAndConditionClick = useCallback(() => {
    navigate("/terms-and-conditions");
  }, [navigate]);

  return (
    <>
      <div className={styles.termsAndConditions}>
        <Navbar />
        <div className={styles.importantDisclosuresDiv}>
          <div className={styles.frameDiv4}>
            <div className={styles.termsConditionsOfUse}>
              Important Disclosures
            </div>
            <div className={styles.bodyItIsOurMissionToBu}>
              <p className={styles.portfolioStrategiesInc}>
                Portfolio Strategies, Inc. (“PSI”) is an SEC registered
                investment adviser. SEC registration does not constitute an
                endorsement of the firm by the Commission nor does it indicate
                that the adviser has attained a particular level of skill or
                ability. PSI may only transact business in those states in which
                it is registered, or qualifies for an exemption or exclusion
                from registration requirements. PSI’swebsite
                (http://psiinvest.com referred to herein as the "Website") is
                limited to the dissemination of general information pertaining
                to its advisory services, together with access to additional
                investment-related information, publications, and links.
                Accordingly, the publication of the Website on the Internet
                should not be construed by any client and/or prospective client
                as PSI’s solicitation to effect, or attempt to effect
                transactions in securities, or the rendering of personalized
                investment advice for compensation, over the Internet. Any
                subsequent, direct communication by PSI with a prospective
                client will be conducted by a representative that is either
                registered or qualifies for an exemption or exclusion from
                registration in the state where the prospective client resides.
              </p>
              <p className={styles.portfolioStrategiesInc}>&nbsp;</p>
              <p className={styles.portfolioStrategiesInc}>
                PSI does not make any representations or warranties as to the
                accuracy, timeliness, suitability, completeness, or relevance of
                any information prepared by any unaffiliated third party,
                whether linked to the Website or incorporated herein, and takes
                no responsibility therefor. All such information is provided
                solely for convenience purposes only and all users thereof
                should be guided accordingly.
              </p>
              <p className={styles.portfolioStrategiesInc}>&nbsp;</p>
              <p className={styles.portfolioStrategiesInc}>
                Certain portions of the Website (i.e. newsletters, articles,
                commentaries, etc.) may contain a discussion of, and/or provide
                access to, PSI’s (and those of other investment and
                non-investment professionals) positions and/or opinions as of a
                specific prior date. Due to various factors, including changing
                market conditions, such discussion may no longer be reflective
                of current positions and/or opinions. Moreover, no client or
                prospective client should assume that any such discussion serves
                as the receipt of, or a substitute for, personalized advice from
                PSI, or from any other investment professional. PSI is neither
                an attorney nor an accountant, and no portion of the Website
                content should be interpreted as legal, accounting or tax
                advice.
              </p>
              <p className={styles.portfolioStrategiesInc}>&nbsp;</p>
              <p className={styles.portfolioStrategiesInc}>
                Each client and prospective client agrees, as a condition
                precedent to his/her/its access to the Website, to release and
                hold harmless PSI, its officers, directors, owners, employees
                and agents from any and all adverse consequences resulting from
                any of his/her/its actions and/or omissions which are
                independent of his/her/its receipt of personalized individual
                advice from PSI
              </p>
              <p className={styles.portfolioStrategiesInc}>&nbsp;</p>
              <p
                className={styles.httpsadviserinfosecgovfi}
              ><a href="https://adviserinfo.sec.gov/firm/summary/106942" target="_blank">{`https://adviserinfo.sec.gov/firm/summary/106942 `}</a></p>
            </div>
          </div>
        </div>
        <div className={styles.importantDisclosuresDiv2}>
          <div className={styles.frameDiv4}>
            <div
              className={styles.termsConditionsOfUse}
            >{`Terms & Conditions of Use`}</div>
            <div className={styles.bodyItIsOurMissionToBu1}>
              <p className={styles.portfolioStrategiesInc}>
                <span
                  className={styles.theseTerms}
                >{`These Terms & Conditions of Use are between you and PSI ("we," "us," and "our"). Your use of the Website, as defined in the Important Disclosures, constitutes an acknowledgement that you have read the most recent version of the Terms & Conditions of Use and that you agree to adhere to its terms. If you do not agree to be bound by these Terms & Conditions of Use, please cease all further use. We reserve the right to amend these Terms & Conditions of Use at any time.`}</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.accessToWebsite}>
                <span className={styles.policiesGoverningUse}>
                  1. Access to Website
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>
                  Limited License. We grant you a non-exclusive,
                  non-transferable limited license to access and make personal
                  use of the Website and not to download (other than page
                  caching) or modify any portion of the Website without our
                  prior written approval. You shall not use any robot, spider or
                  other device to monitor the Website in any manner. We may
                  terminate this license at any time.
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.accessToWebsite}>
                <span className={styles.policiesGoverningUse}>
                  2. Policies Governing Use of the Website
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>
                  Links to the Website. You are expressly prohibited from
                  framing or linking or otherwise using or displaying the
                  Website or any portion thereof in such a manner so that it
                  appears to be part of your own or someone else's website.
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>
                  Privacy Policy. Our use of your information shall be governed
                  at all times by our Privacy Policy.
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>
                  Links to Other Websites. We may place links on the Website to
                  other websites on the Internet that are owned or operated by
                  third parties. You acknowledge and agree that we are not
                  responsible for, nor do we endorse or support, the operation
                  of or content located on any such website, and we cannot and
                  do not warrant that the content of such websites is accurate,
                  complete, legal and/or inoffensive. By linking to these third
                  party websites, you acknowledge and agree that you may not
                  make any claim against us for any damages or losses of any
                  kind arising from the third-party website and/or the link.
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.accessToWebsite}>
                <span className={styles.policiesGoverningUse}>
                  3. Disclaimer of Warranty; No Consequential Damages;
                  Limitation of Liability
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>
                  Disclaimer of Warranty. YOU ACKNOWLEDGE THAT YOUR USE OF THE
                  WEBSITE IS ENTIRELY AT YOUR OWN RISK. The Website is provided
                  as is, without warranties of any kind, either express or
                  implied, including, but not limited to, warranties of title or
                  implied warranties of merchantability or fitness for a
                  particular purpose, or warranties of non-infringement. To the
                  fullest extent permissible by law, We make no warranties and
                  shall not be liable for the use of the Website, including,
                  without limitation, any interruption of or error in the
                  services under any circumstances, including, but not limited
                  to, our negligence.
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>
                  Limitation of Liability. Under no circumstances shall we be
                  liable for any special or consequential damages that are
                  directly or indirectly related to the use of, or the inability
                  to use, the Website, even if we have been advised of the
                  possibility of such damages. Some states do not allow the
                  exclusion or limitation of incidental or consequential
                  damages, so the above limitation or exclusion may not apply to
                  you.
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.accessToWebsite}>
                <span className={styles.policiesGoverningUse}>
                  4. Miscellaneous
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>
                  Prohibited Uses. You expressly agree not to use the Website in
                  a manner that is prohibited by any law or regulation, or to
                  facilitate the violation of any law or regulation. You
                  acknowledge that prohibited conduct includes, but is not
                  limited to, use of the Website to invade the privacy of third
                  parties, impersonation of our personnel or other parties or
                  entities, transmitting abusive, profane, libellous,
                  slanderous, threatening or otherwise harassing material via
                  e-mail or your personal Web page(s). You also agree not to use
                  the
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>
                  Website to violate the security of the Website or attempt to
                  utilize another user's account name or persona without
                  authorization from that user.
                </span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span
                  className={styles.theseTerms}
                >{`Choice of Law; Jurisdiction. These Terms & Conditions of Use shall be governed and interpreted in accordance with the substantive law of the State of New Jersey without regard to its conflict of law provisions. You agree to the exclusive jurisdiction of the courts located within Bergen County, State of New Jersey.`}</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span
                  className={styles.theseTerms}
                >{`Severability. If any provision of these Terms & Conditions of Use is deemed invalid or unenforceable, that provision shall be deemed severable and shall not affect the validity, legality or enforceability of the remaining provisions.`}</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span
                  className={styles.theseTerms}
                >{`Survival. Any section of these Terms & Conditions of Use which by its terms and nature is meant to survive the termination of these Terms & Conditions of Use, shall survive such termination.`}</span>
              </p>
              <p className={styles.portfolioStrategiesInc}>
                <span className={styles.theseTerms}>&nbsp;</span>
              </p>
              <p className={styles.httpsadviserinfosecgovfi}>
                <span
                  className={styles.theseTerms}
                >{`Entire Agreement. These Terms & Conditions of Use set forth the entire understanding and agreement between us with respect to the subject matter hereof`}</span>
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      {isMenuPSOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMenuPS}
        >
          <MenuPS onClose={closeMenuPS} />
        </PortalPopup>
      )}
    </>
  );
};

export default TermsAndConditions;
