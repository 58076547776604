import {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { debounce, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuPS from "../components/MenuPS";
import PortalPopup from "../components/PortalPopup";
import styles from "./HOMEPAGE.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import { Parallax } from "react-parallax";
import { motion, Variants } from "framer-motion";

const HOMEPAGE: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isVideoPlaying, setVideoPlaying] = useState(false);

  const videoPlayer = useRef(null);

  const onButton1Click = useCallback(() => {
    navigate("/our-process-page");
  }, [navigate]);

  const onButton2Click = useCallback(() => {
    navigate("/our-process-page");
  }, [navigate]);

  const onButton3Click = useCallback(() => {
    navigate("/our-process-page");
  }, [navigate]);

  const onButtonClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const cardVariants: Variants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 1.2,
      },
    },
  };

  useEffect(() => {
    window.addEventListener("scroll", debounce(onScroll, 200));

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const onScroll = () => {
    const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
    console.log(`onScroll, window.scrollY: ${scrollY} myRef.scrollTop:`);

    // @ts-ignore
    // videoPlayer.current.play();
    setTimeout(function () {
      // @ts-ignore
      // videoPlayer.current.pause();
    }, 100);
  };

  return (
    <>
      <div className={styles.hOMEPAGEDiv} onScroll={onScroll}>
        <Navbar />

        <div
          className={styles.bannerContaierDiv}
          data-scroll-to="bannerContaierContainer"
          id="bannerContaierContainer"
        >
          <video
            muted
            loop
            className={styles.backgroundVideo}
            ref={videoPlayer}
            autoPlay
            controls={false}
            playsInline
            preload="auto"
            poster="/img/poster-min.jpeg"
          >
            <source src="/PS_video_v2.mp4" type="video/mp4" />
          </video>

          <div className={styles.frameDiv4}>
            <motion.div
              className="card-container"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <motion.div className="card" variants={cardVariants}>
                <h1 className={styles.investInSomethingBetter}>
                  A better approach <br />
                  to investing.
                </h1>
              </motion.div>
            </motion.div>
            <div className={styles.hr_yellow}></div>
            <h3 className={styles.aSecureStructuredApproach}>
              An intelligent, logical, disciplined system for investment
              management
            </h3>
          </div>
        </div>
        <div
          className={styles.whyPortfolioContents}
          data-scroll-to="whyPortfolioContents"
          id="whyPortfolioContents"
        >
          <div className={styles.whyPortafolioCards}>
            <div className={styles.frameDiv9}>
              <div className={styles.frameDiv10}>
                <img
                  className={styles.structure1Icon1}
                  alt=""
                  src="../svg/analysis_icon.svg"
                />
                <div className={styles.experienceQualitySecurit}>
                  ANALYSIS-DISCIPLINE-STRUCTURE
                </div>
              </div>
              <div className={styles.frameDiv11}>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <div className={styles.whyPortfolioStrategies}>
                      Why Portfolio Strategies
                    </div>
                  </motion.div>
                </motion.div>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <div className={styles.asYourFiduciaryPartnerWe}>
                      While the owners of every business want the business to
                      prosper, our credo at Portfolio Strategies is that the
                      purpose of the business, as a business, is to satisfy our
                      clients.
                    </div>
                    <h2 className={styles.why_two_includes}>
                      This includes two areas.
                    </h2>
                    <div className={styles.asYourFiduciaryPartnerWe}>
                      <strong>One:</strong> performing the functions of the
                      business --- Investment management --- with a strong
                      desire to achieve excellence in methodology and results.
                      <br />
                      <br />
                      <strong>Two:</strong> to serve our clients’ needs, as
                      people, with dedication, care, and concern.
                    </div>
                  </motion.div>
                </motion.div>
              </div>
            </div>
            <div className={styles.frameDiv12}>
              <div className={styles.disciplineCard}>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <div className={styles.disciplineText}>
                      <div className={styles.disciplineDiv}>
                        <img
                          className={styles.structure1Icon2}
                          alt=""
                          src="../img/structure-3@2x.png"
                        />
                        <div className={styles.cutomizedDiv}>Analysis</div>
                      </div>
                      <div className={styles.timeTestedFundamentalsAndT}>
                        Comprehensive search for, and evaluation of, potentially
                        favorable investments. Tested procedures that have
                        proven successful.
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              </div>
              <div className={styles.structureCard}>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <div className={styles.structureText}>
                      <div className={styles.disciplineDiv}>
                        <img
                          className={styles.structure2Icon}
                          alt=""
                          src="../img/structure-12@2x.png"
                        />
                        <div className={styles.activeDiv}>Discipline</div>
                      </div>
                      <div className={styles.aHybridInvestmentApproachT}>
                        Clearly defined step-by-step procedures for arriving at
                        investment decisions.
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              </div>
              <div className={styles.analysisCard}>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <div className={styles.analysisText}>
                      <div className={styles.disciplineDiv}>
                        <img
                          className={styles.structure2Icon2}
                          alt=""
                          src="../img/structure-2@2x.png"
                        />
                        <div className={styles.activeDiv}>Structure</div>
                      </div>
                      <div className={styles.aHybridInvestmentApproachT}>
                        Reliable proprietary filtering and statistical software
                        combined with commercial programs to help form a
                        comprehensive, coherent investment decision mechanism.
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={styles.competitiveAdvanatgeSection}
          data-scroll-to="ourCompetitiveAdvantage"
          id="ourCompetitiveAdvantage"
        >
          <div className={styles.frameDiv13}>
            <div className={styles.frameDiv15}>
              <div className={styles.value1Discipline1}>
                <img
                  className={styles.structure1Icon3}
                  alt=""
                  src="../svg/competitive_icon.svg"
                />
                <h4 className={styles.disciplineStructureAnalysisH4}>
                  Dedication—Personalization—Accessibility
                </h4>
              </div>
              <div className={styles.textDiv1}>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <h2 className={styles.tittleWeInvestInYourFin1}>
                      Our Competitive Advantage
                    </h2>
                    <br />
                    <button className={styles.button} onClick={onButtonClick}>
                      <span className={styles.contactUsButton}>Contact Us</span>
                    </button>
                  </motion.div>
                </motion.div>
              </div>
            </div>
            <div className={styles.frameDiv14}>
              <table>
                <tbody>
                  <tr className={styles.competitive_list_row}>
                    <td
                      style={{
                        paddingRight: "12px",
                        verticalAlign: "top",
                        paddingTop: "10px",
                      }}
                    >
                      <img src="../svg/gold_check.svg" alt="Gold check mark" />
                    </td>
                    <td className={styles.competitive_list_item}>
                      An experienced firm that will give serious attention as
                      how to best achieve our clients’ investment goals.
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingRight: "12px",
                        verticalAlign: "top",
                        paddingTop: "10px",
                      }}
                    >
                      <img src="../svg/gold_check.svg" alt="Gold check mark" />
                    </td>
                    <td className={styles.competitive_list_item}>
                      We combine intelligence with technology to achieve a very
                      simple objective --- to satisfy our clients.
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingRight: "12px",
                        verticalAlign: "top",
                        paddingTop: "10px",
                      }}
                    >
                      <img src="../svg/gold_check.svg" alt="Gold check mark" />
                    </td>
                    <td className={styles.competitive_list_item}>
                      Satisfying our clients is the goal and fundamental
                      attitude of every member of our firm.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className={styles.competitiveAdvanatgeSection}
          data-scroll-to="ourCompetitiveAdvantage"
          id="ourCompetitiveAdvantage"
        >
          <div className={styles.frameDiv131}>
            <div className={styles.frameDiv15}>
              <div className={styles.textDiv1} style={{ position: "relative" }}>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <img
                      className={styles.pexelsAndreaPiacquadio37600Icon1}
                      alt=""
                      src="../img/buildings.jpg"
                    />
                  </motion.div>
                </motion.div>
              </div>
            </div>
            <div className={styles.frameDiv142} style={{ marginTop: "150px" }}>
              <div>
                <img
                  className={styles.structure1Icon32}
                  alt=""
                  src="../svg/we_offer_icon.svg"
                />
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <h2 className={styles.tittleWeInvestInYourFin1}>
                      We Offer
                    </h2>
                    <p style={{ marginBottom: "375px" }}>
                      The benefits of a personalized investment management firm
                      with a solid foundation, a straightforward approach,
                      decades of experience, and demonstrated success.
                    </p>
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ourInvestmentProcess}>
          <div className={styles.frameDiv132}>
            <div className={styles.frameDiv15}></div>
            <div className={styles.frameDiv142} style={{ marginTop: "157px" }}>
              <img
                className={styles.ourInvestmentIcon}
                alt=""
                src="../svg/our_investment_icon.svg"
              />
              <h4 className={styles.our_investment_title}>
                Our Investment Decision Procedure
              </h4>
              <p className={styles.our_investment_paragraph}>
                The benefits of a personalized investment management firm with a
                solid foundation, a straightforward approach, decades of
                experience, and demonstrated success.
              </p>
            </div>
          </div>
          <div className={styles.frameDiv132}>
            <div className={styles.frameDiv15}>
              <p
                className={styles.our_investment_paragraph}
                style={{ marginTop: "155px" }}
              >
                We use a multi-step process to filter and locate sectors within
                markets, and ETFs within these sectors that show the most
                potential. To do this, we have developed a structured,
                systematic approach that we use in conjunction with our
                comprehensive proprietary software along with a sophisticated
                charting program and a well-defined analysis procedure
              </p>
              <p
                className={styles.our_investment_paragraph}
                style={{ marginBottom: "375px" }}
              >
                Throughout the day, we monitor, via a financial website, the
                ETFs in which we have invested, as well as all ETFs that have
                been deemed viable potentials. This means that whatever we
                invest for your portfolio is given daily supervision. We also
                have a proprietary portfolio management program, developed and
                refined over a period of twenty years, and subject to frequent
                enhancements, that we use for allocating investments to
                portfolios in an efficacious manner according to what seems best
                given current holdings and other characteristics of the
                portfolio.
              </p>
            </div>
            <div className={styles.frameDiv142} style={{ marginTop: "185px" }}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div className="card" variants={cardVariants}>
                  <img
                    src="../img/multi_steps-min.jpg"
                    alt=""
                    style={{ marginTop: "-300px" }}
                    className={styles.our_investment_img}
                  />
                </motion.div>
              </motion.div>
            </div>
          </div>
          <div className={styles.frameDiv132}>
            <div className={styles.frameDiv15}>
              <img
                src="../img/not_short_term.jpg"
                alt=""
                style={{ marginTop: "-200px" }}
                className={styles.our_investment_img}
              />
            </div>
            <div className={styles.frameDiv142} style={{ marginTop: "0" }}>
              <p
                className={styles.our_investment_paragraph}
                style={{ marginTop: "-200px" }}
              >
                We are never complacent regarding our procedure and our
                software. We continually seek improvement in all areas. This
                includes the development of a mapping program that lets us set
                target ranges for various investment categories or for just
                individual ETFs. This mapping could be applied globally or just
                to a specific set of portfolios. With this program we can
                overview globally or narrowly, the degree of compliance we have
                with our target ranges. This lets us make changes as appropriate
                for those portfolios that might benefit most from those changes.
                By contrast, making the same changes for all portfolios --- one
                size fits all --- may not be the best alternative.
              </p>
            </div>
          </div>
          <div className={styles.frameDiv132}>
            <div className={styles.frameDiv15}>
              <p
                className={styles.our_investment_paragraph}
                style={{ marginTop: "145px", marginBottom: "280px" }}
              >
                We are not short-term traders. We hold investments for as long
                as they are viable and continue to perform satisfactorily. We
                may hold some investments for years, others for months or just
                weeks, depending on how they perform and what we believe their
                future looks like. We are, however, proactive, and we do make
                changes as appropriate to either enhance potential and/or to
                reduce volatility. Sometimes these changes involve an entire
                holding, sometimes the changes are incremental.
              </p>
            </div>
            <div
              className={styles.frameDiv142}
              style={{ marginTop: "0", position: "relative" }}
            >
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div className="card" variants={cardVariants}>
                  <img
                    src="../img/skylines.jpg"
                    alt=""
                    style={{
                      marginTop: "150px",
                      position: "absolute",
                      top: "-520px",
                      zIndex: "2",
                    }}
                    className={styles.our_investment_img}
                  />
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
        <div className={styles.portfolio_care}>
          <p className={styles.portfolio_care_p}>
            We will give your portfolio the care and attention it deserves with
            the clear objective to bring your financial goals to fruition and
            provide you with very satisfactory results.
          </p>
        </div>
        <div className={styles.our_relationship}>
          <div className={styles.our_relationship_left}>
            <img
              className={styles.our_relationship_left_img}
              src="../img/relationship.jpg"
              alt="Client relationships"
            />
          </div>
          <div className={styles.our_relationship_right}>
            <h4 className={styles.our_relationship_right_h4}>
              Our Relationship with Clients
            </h4>
            <p className={styles.our_relationship_right_p}>
              There is a human aspect to what we offer. Portfolios are not just
              numbers. There is a person or people behind every portfolio. Along
              with our comprehensive, analytical investment procedure, we
              provide an attentive, responsive attitude toward helping our
              clients with every aspect of achieving their financial goals.
              Every client has their own personal situation, and within the
              framework of our portfolio management software, we try to
              customize as much as possible the way a portfolio will be managed.
              We care about every client as a person, not as a number. Everyone
              in our firm, from the investment committee to the account
              servicing staff, is available to discuss with every client, and to
              help with whatever we can do to satisfy their needs. Every client,
              whether big or small, is important to us.
            </p>
          </div>
        </div>
        <div className={styles.firstSeccionDiv}>
          <div className={styles.frameDiv5}>
            <div className={styles.frameDiv6}>
              <div className={styles.value1Discipline}>
                <div className={styles.frameDiv7}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <img
                        className={styles.structure1Icon}
                        alt=""
                        src="../svg/puzzle.svg"
                      />
                    </motion.div>
                  </motion.div>
                </div>
                <h4 className={styles.disciplineStructureAnalysisH4}>
                  EXPERIENCE-QUALITY-SECURITY
                </h4>
              </div>
              <div className={styles.textDiv}>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <h2 className={styles.tittleWeInvestInYourFin}>
                      We invest to achieve your financial goals
                    </h2>
                    <table>
                      <tbody>
                        <tr className={styles.competitive_list_row}>
                          <td
                            style={{
                              paddingRight: "12px",
                              verticalAlign: "top",
                              paddingTop: "10px",
                            }}
                          >
                            <img
                              src="../svg/gold_check.svg"
                              alt="Gold check mark"
                            />
                          </td>
                          <td className={styles.we_invest_list_item}>
                            With expertise
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              paddingRight: "12px",
                              verticalAlign: "top",
                              paddingTop: "10px",
                            }}
                          >
                            <img
                              src="../svg/gold_check.svg"
                              alt="Gold check mark"
                            />
                          </td>
                          <td className={styles.we_invest_list_item}>
                            With concern for your needs
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              paddingRight: "12px",
                              verticalAlign: "top",
                              paddingTop: "10px",
                            }}
                          >
                            <img
                              src="../svg/gold_check.svg"
                              alt="Gold check mark"
                            />
                          </td>
                          <td className={styles.we_invest_list_item}>
                            With a coherent investment procedure
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <a href="#ourApproach" className={styles.our_approach_btn}>
                      Learn more about our approach
                    </a>
                  </motion.div>
                </motion.div>
              </div>
            </div>
            <div className={styles.frameDiv8}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div className="card" variants={cardVariants}>
                  <img
                    className={styles.pexelsAndreaPiacquadio37600Icon}
                    alt=""
                    src="../img/pexelsandreapiacquadio3760067-2@2x.png"
                  />
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>

        <div
          className={styles.ourApproachSection}
          data-scroll-to="ourApproach"
          id="ourApproach"
        >
          <div className={styles.columnsDiv}>
            <div className={styles.column1Div}>
              <div className={styles.div}>
                <div className={styles.div1}>
                  <div className={styles.div2}>1</div>
                </div>
                <div className={styles.provenStrategyDiv}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div className={styles.personalizedAttentionDiv}>
                        Proven strategy.
                      </div>
                      <div className={styles.bodyItIsOurMissionToBu2}>
                        A logical, tested procedure that serves to perform well
                        over time throughout calm and stormy market conditions.
                      </div>
                      <a
                        href="#ourProcedure"
                        className={styles.our_procedure_link}
                      >
                        OUR iNVESTMENT PROCEDURE
                      </a>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              <div className={styles.div}>
                <div className={styles.div1}>
                  <div className={styles.div2}>2</div>
                </div>
                <div className={styles.provenStrategyDiv}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div className={styles.personalizedAttentionDiv}>
                        Personalized attention.
                      </div>
                      <div className={styles.bodyItIsOurMissionToBu2}>
                        From initial account set up and further, our personnel
                        are dedicated to providing a personalized experience and
                        responsive service. We remain vigilant and proactive to
                        try to bring maximum success to your investments. We are
                        always readily available to discuss all aspects of your
                        account.
                      </div>
                      <a
                        href="#ourRelationship"
                        className={styles.our_procedure_link}
                      >
                        oUR relationship with clients
                      </a>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
            <div className={styles.column1Div}>
              <div className={styles.div}>
                <div className={styles.div1}>
                  <div className={styles.div2}>3</div>
                </div>
                <div className={styles.provenStrategyDiv}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div className={styles.establishedStabilityDiv}>
                        Established stability.
                      </div>
                      <div className={styles.bodyItIsOurMissionToBu2}>
                        As an established firm with 40 years of experience in
                        investment management, we invite you to bring that
                        experience to work for you.
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              <div className={styles.div}>
                <div className={styles.div1}>
                  <div className={styles.div2}>4</div>
                </div>
                <div className={styles.provenStrategyDiv}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div
                        className={styles.establishedStabilityDiv}
                      >{`Fee-Only structure. `}</div>
                      <div className={styles.bodyItIsOurMissionToBu2}>
                        Portfolio Strategies does not earn commissions or sales
                        charges. Our fee-only billing based on portfolio balance
                        means that our compensation is strictly based upon
                        client retention with preservation and growth of our
                        clients’ assets.
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              <div className={styles.div}>
                <div className={styles.div1}>
                  <div className={styles.div2}>5</div>
                </div>
                <div className={styles.provenStrategyDiv}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div className={styles.personalizedAttentionDiv}>
                        Comprehensive service.
                      </div>
                      <div className={styles.bodyItIsOurMissionToBu2}>
                        We provide financial planning related to your account,
                        including tax-related reporting, charitable giving
                        assistance, transfers, inheritance management, and more.
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.whyPortfolioContents1}>
          <div className={styles.whyPortafolioCards1}>
            <div className={styles.frameDiv16}>
              <div className={styles.frameDiv17}>
                <div className={styles.informedMeasuredAdaptableDiv}>
                  Informed—Measured—Adaptable
                </div>
              </div>
              <div className={styles.frameDiv18}>
                <div className={styles.ourApproachTitle}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    Our Approach
                  </motion.div>
                </div>
                <div className={styles.ourApproachP}>
                  We use an evidence-based, calculated investment approach for a
                  low volatility profile with steady, long-term growth.
                </div>
              </div>
            </div>
            <div className={styles.frameDiv19}>
              <div className={styles.disciplineCard1}>
                <div className={styles.disciplineText}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div className={styles.disciplineDiv}>
                        <img
                          className={styles.structure2Icon1}
                          alt=""
                          src="../img/structure-21@2x.png"
                        />
                        <div className={styles.cutomizedDiv}>Customized</div>
                      </div>
                      <div className={styles.weBelieveThatInvestingShou}>
                        We feel that investing should be personalized. All
                        aspects of our approach lead to evaluation and
                        implementation of investments corresponding to a
                        client’s individual objectives.
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              <div className={styles.structureCard1}>
                <div className={styles.structureText}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div className={styles.disciplineDiv}>
                        <img
                          className={styles.structure3Icon1}
                          alt=""
                          src="../img/structure-31@2x.png"
                        />
                        <div className={styles.activeDiv}>Active</div>
                      </div>
                      <div className={styles.withDailySupervisionOfClie}>
                        With daily supervision of client holdings, we are nimble
                        and responsive, without being overreactive. We monitor
                        economic and market conditions to utilize change as a
                        path for locating and seizing viable investment
                        opportunities.
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              <div className={styles.analysisCard1}>
                <div className={styles.disciplineText}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div className={styles.disciplineDiv}>
                        <img
                          className={styles.structure3Icon1}
                          alt=""
                          src="../img/structure-22@2x.png"
                        />
                        <div className={styles.activeDiv}>Integrated</div>
                      </div>
                      <div className={styles.withDailySupervisionOfClie}>
                        We continually strive to create and refine unique
                        software and decision procedures that we anticipate will
                        give us an edge in successfully managing our portfolios.
                        We remain on the lookout as well, for new sectors that
                        we expect to be successful in the future.
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.oURAPPROACHBUTTON}>
          <div className={styles.frameDiv20}>
            <motion.div
              className="card-container"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <motion.div className="card" variants={cardVariants}>
                <button className={styles.button3} onClick={onButton3Click}>
                  <div className={styles.unstyledButtonDiv}>
                    <div className={styles.buttonDiv}>
                      our investment procedures
                    </div>
                  </div>
                </button>
              </motion.div>
            </motion.div>
            <motion.div
              className="card-container"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <motion.div className="card" variants={cardVariants}>
                <button className={styles.button3} onClick={onButton3Click}>
                  <div className={styles.unstyledButtonDiv}>
                    <div className={styles.buttonDiv}>client relations</div>
                  </div>
                </button>
              </motion.div>
            </motion.div>
          </div>
        </div>
        <div className={styles.ourInvestmentProcess}>
          <div className={styles.frameDiv132}>
            <div className={styles.client_onboard_title}>
              <img
                className={styles.client_onboard_icon}
                alt=""
                src="../svg/client_onboard_icon.svg"
              />
              <h4 className={styles.client_onboard_title_h4}>
                Client Onboarding Process
              </h4>
              <p className={styles.our_investment_paragraph}>
                We make it convenient and straightforward to have us invest for
                you.
              </p>
            </div>
          </div>
          <div className={styles.onboarding_row}>
            <div className={styles.onboarding_left}>
              <img src="../img/onboard_1.jpg" alt="" />
            </div>
            <div className={styles.onboarding_right}>
              <div className={styles.flex}>
                <div className={styles.onboarding_indicator}>1</div>
                <h5 className={styles.onboarding_title}>
                  Initial Consultation
                </h5>
              </div>

              <p className={styles.onboarding_p}>
                Your initial contact by phone, form, or email begins a free
                consultation with one of our investment managers who will
                discuss your financial goals, explain more about the Portfolio
                Strategies approach, answer any questions you may have, and
                obtain basic information to complete an account application.
              </p>
            </div>
            <div className={styles.onboarding_indicators}>
              <img
                src="../svg/indicators.svg"
                alt=""
                className={styles.onboarding_indicators_icons}
              />
            </div>
          </div>
          <div className={styles.onboarding_row}>
            <div className={styles.onboarding_left}>
              <div className={styles.flex}>
                <div className={styles.onboarding_indicator}>2</div>
                <h5 className={styles.onboarding_title}>Implementation</h5>
              </div>

              <p className={styles.onboarding_p}>
                Once you have made a choice to begin investing with us, we
                schedule a brief discovery meeting to get to know you and the
                motivations, values, and goals that will guide our investment
                decisions. When we receive notification from Fidelity
                Investments that your account is open, we will direct you on
                wire transfer details and begin trading on your behalf.
              </p>
            </div>
            <div className={styles.onboarding_right}>
              <img src="../img/onboard_2.jpg" alt="" />
            </div>
            <div className={styles.onboarding_indicators}>
              <img
                src="../svg/indicators2.svg"
                alt=""
                className={styles.onboarding_indicators_icons}
              />
            </div>
          </div>
          <div className={styles.onboarding_row}>
            <div className={styles.onboarding_left}>
              <img src="../img/onboard_3.jpg" alt="" />
            </div>
            <div className={styles.onboarding_right}>
              <div className={styles.flex}>
                <div className={styles.onboarding_indicator}>3</div>
                <h5 className={styles.onboarding_title}>Reporting</h5>
              </div>

              <p className={styles.onboarding_p}>
                Based on your goals, we build a diversified portfolio and remain
                extremely active in your account. With our daily supervision on
                current investments and continual monitoring of trends, we make
                calculated determinations through a multi-step process with
                careful analysis on when, if, what, and where to trade.
              </p>
            </div>
            <div className={styles.onboarding_indicators}>
              <img
                src="../svg/indicators3.svg"
                alt=""
                className={styles.onboarding_indicators_icons}
              />
            </div>
          </div>
        </div>
        <ContactUs />
        <Footer />
      </div>
    </>
  );
};

export default HOMEPAGE;
