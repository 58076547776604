import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MenuPS.module.css";

type MenuPSType = {
  onClose?: () => void;
};

const MenuPS: FunctionComponent<MenuPSType> = ({ onClose }) => {
  const navigate = useNavigate();

  const onHOMEButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutUSButtonClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onOurProcessButtonClick = useCallback(() => {
    navigate("/our-process-page");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  return (
    <div className={styles.menuPS}>
      <div className={styles.rectangleDiv} />
      <div className={styles.frameDiv}>
        <div className={styles.frameDiv1}>
          <button className={styles.hOMEButton} onClick={onHOMEButtonClick}>
            HOME
          </button>
          <button className={styles.hOMEButton} onClick={onAboutUSButtonClick}>
            about US
          </button>
          <button
            className={styles.hOMEButton}
            onClick={onOurProcessButtonClick}
          >
            Our process
          </button>
          <button className={styles.frameButton} onClick={onFrameButtonClick}>
            <button className={styles.contactUsButton}>Contact Us</button>
          </button>
        </div>
        <div className={styles.socialMediaDiv}>
          <div className={styles.groupDiv}>

            <a href="mailto:nsavitz@psiinvest.com" className="groupButton">
              <img
                className={styles.shapeIcon2}
                alt=""
                src="../svg/email_icon.svg"
              />
            </a>
          </div>
        </div>
      </div>
      <button className={styles.hamburgerMenuButton} onClick={onClose}>
        <img className={styles.groupIcon} alt="" src="../svg/group-1.svg" />
      </button>
    </div>
  );
};

export default MenuPS;
