import { FunctionComponent, useState, useCallback } from "react";
import {TextareaAutosize, TextField} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuPS from "../components/MenuPS";
import PortalPopup from "../components/PortalPopup";
import styles from "./ContactUs.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ContactUsThanks: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.contactUsDiv}>
        <Navbar />
        <div className={styles.contactUsDiv1}>
          <div className={styles.contactUsDiv2}>
            <form className={styles.contactForm}>
              <div className={styles.formHeaderDiv}>
                <div className={styles.formTitleDiv}>Thanks</div>
                <div className={styles.formTitleDiv}>We will be in Touch</div>
              </div>
              <div className={styles.formDiv}>
                <div className={styles.formFieldsDiv}>
                  <div className={styles.destinationNameInput}>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ContactUsThanks;
