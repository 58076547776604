import {FunctionComponent, useState, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import MenuPS from "../components/MenuPS";
import PortalPopup from "../components/PortalPopup";
import styles from "./PrivacyPolicy.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const PrivacyPolicy: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.privacyPolicyDiv}>
        <Navbar/>
        <div className={styles.frameDiv4}>
          <div className={styles.frameDiv5}>
            <table className={styles.factsTable}>
              <tbody>
              <tr>
                <td className={styles.tableFacts}>FACTS</td>
                <td className={styles.tableFactsTitle}>WHAT DOES PORTFOLIO STRATEGIES INC. DO WITH YOUR PERSONAL
                  INFORMATION?
                </td>
              </tr>
              <tr>
                <td>WHY?</td>
                <td>Financial companies choose how they share your personal information. Federal law gives consumers the
                  right to limit some but not all sharing. Federal law also requires us to tell you how we collect,
                  share, and protect your personal information. Please read this notice carefully to understand what we
                  do.
                </td>
              </tr>
              <tr>
                <td>WHAT?</td>
                <td>The types of personal information we collect and share depend on the product or service you have
                  with us. This information can include:
                  <br/>
                  <ul>
                    <li>Social Security number and income</li>
                    <li>Account balances and transaction history</li>
                    <li>Investment experiences and account transactions</li>
                  </ul>


                  <br/>

                  When you are no longer our customer, we continue to share information as described in this notice.
                </td>
              </tr>
              <tr>
                <td>HOW?</td>
                <td>All financial companies need to share customer’s personal information to run their everyday
                  business. In the section below, we list the reasons financial companies can share their customer’s
                  personal information; the reasons Portfolio Strategies, Inc. (“PSI”) chooses to share; and whether you
                  can limit this sharing.
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.frameDiv18}>
          <div className={styles.frameDiv5}>
            <div className={styles.frameDiv20}>
              <div className={styles.frameDiv21}>
                <div className={styles.reasonsWeCanShareYourPers}>
                  Reasons we can share your personal information
                </div>
              </div>
              <div className={styles.frameDiv22}>
                <div className={styles.reasonsWeCanShareYourPers}>
                  Does PSI SHARE?
                </div>
              </div>
              <div className={styles.frameDiv23}>
                <div className={styles.canYouLimitThisSharing}>
                  Can you limit this sharing?
                </div>
              </div>
            </div>
            <div className={styles.frameDiv24}>
              <div className={styles.frameDiv25}>
                <div className={styles.forOurEverydayBusinessPurp}>
                  <p className={styles.theTypesOf}>
                    <b>For our everyday business purposes—</b>
                  </p>
                  <p className={styles.whenYouAre}>
                    <span className={styles.suchAsTo}>
                      such as to process your transactions, maintain your
                      account(s), respond to court orders and legal
                      investigations, or report to credit bureaus
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.yESDiv}>YES</div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.nODiv}>NO</div>
              </div>
            </div>
            <div className={styles.frameDiv24}>
              <div className={styles.frameDiv29}>
                <div className={styles.forOurEverydayBusinessPurp}>
                  <p className={styles.theTypesOf}>
                    <b>For our marketing purposes—</b>
                  </p>
                  <p className={styles.whenYouAre}>
                    <span className={styles.suchAsTo}>
                      to offer our products and services to you
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.nODiv}>YES</div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.nODiv}>NO</div>
              </div>
            </div>
            <div className={styles.frameDiv24}>
              <div className={styles.frameDiv33}>
                <b className={styles.forOurEverydayBusinessPurp}>
                  For joint marketing with other financial companies
                </b>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.noDiv}>No</div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.noDiv}>we don’t share</div>
              </div>
            </div>
            <div className={styles.frameDiv24}>
              <div className={styles.frameDiv37}>
                <div className={styles.forOurEverydayBusinessPurp}>
                  <p className={styles.theTypesOf}>
                    <b>For our affiliates’ everyday business purposes—</b>
                  </p>
                  <p className={styles.whenYouAre}>
                    <span className={styles.suchAsTo}>
                      information about your transactions and experiences
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.noDiv}>no</div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.noDiv}>we don’t share</div>
              </div>
            </div>
            <div className={styles.frameDiv24}>
              <div className={styles.frameDiv41}>
                <div className={styles.forOurEverydayBusinessPurp}>
                  <p className={styles.theTypesOf}>
                    <b>For our affiliates’ everyday business purposes—</b>
                  </p>
                  <p className={styles.whenYouAre}>
                    <span className={styles.suchAsTo}>
                      information about your transactions and experiences
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.noDiv}>no</div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.noDiv}>we don’t share</div>
              </div>
            </div>
            <div className={styles.frameDiv24}>
              <div className={styles.frameDiv45}>
                <div className={styles.forOurEverydayBusinessPurp}>
                  <p className={styles.theTypesOf}>
                    <b>For our affiliates’ everyday business purposes—</b>
                  </p>
                  <p className={styles.whenYouAre}>
                    <span className={styles.suchAsTo}>
                      information about your credit worthiness
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.noDiv}>no</div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.noDiv}>we don’t share</div>
              </div>
            </div>
            <div className={styles.frameDiv24}>
              <div className={styles.frameDiv49}>
                <b className={styles.forNonaffiliatesToMarketTo}>
                  For nonaffiliates to market to you
                </b>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.noDiv}>no</div>
              </div>
              <div className={styles.frameDiv26}>
                <div className={styles.noDiv}>we don’t share</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameDiv52}>
          <div className={styles.frameDiv53}>
            <div className={styles.frameDiv54}>
              <div className={styles.qUESTIONSDiv}>QUESTIONS</div>
            </div>
            <div className={styles.frameDiv55}>
              <div className={styles.cALL2015714100Div}>CALL 201-571-4100</div>
            </div>
          </div>
        </div>
        <div className={styles.frameDiv56}>
          <div className={styles.frameDiv5}>
            <div className={styles.frameDiv58}>
              <div className={styles.frameDiv59}>
                <div className={styles.whoWeAre}>Who we are</div>
              </div>
            </div>
            <div className={styles.frameDiv9}>
              <div className={styles.frameDiv61}>
                <b className={styles.whoIsProvidingThisNotice}>
                  Who is providing this notice?
                </b>
              </div>
              <div className={styles.frameDiv62}>
                <div className={styles.whoIsProvidingThisNotice}>
                  Portfolio Strategies, Inc.
                </div>
              </div>
            </div>
            <div className={styles.frameDiv58}>
              <div className={styles.frameDiv59}>
                <div className={styles.whoWeAre}>what we do</div>
              </div>
            </div>
            <div className={styles.frameDiv9}>
              <div className={styles.frameDiv61}>
                <b className={styles.howDoesPSIProtectMyPerson}>
                  How does PSI protect my personal information?
                </b>
              </div>
              <div className={styles.frameDiv62}>
                <div className={styles.howDoesPSIProtectMyPerson}>
                  To protect your personal information from unauthorized access
                  and use, we use security measures that comply with federal
                  law. These measures include computer safeguards and secured
                  files and buildings.
                </div>
              </div>
            </div>
            <div className={styles.frameDiv9}>
              <div className={styles.frameDiv61}>
                <b className={styles.howDoesPSIProtectMyPerson}>
                  How does PSI protect my personal information?
                </b>
              </div>
              <div className={styles.frameDiv70}>
                <div className={styles.howDoesPSIProtectMyPerson}>
                  <p className={styles.theTypesOf}>
                    We collect your personal information, for example, when you:
                  </p>
                  <p className={styles.theTypesOf}>&nbsp;</p>
                  <ul className={styles.sharingForAffiliateseveryda}>
                    <li className={styles.socialSecurityNumber}>
                      Social Security number and income
                    </li>
                    <li className={styles.socialSecurityNumber}>
                      Account balances and transaction history
                    </li>
                    <li className={styles.socialSecurityNumber}>
                      Investment experience and account transactions
                    </li>
                  </ul>
                  <p className={styles.theTypesOf}>&nbsp;</p>
                  <p className={styles.whenYouAre}>
                    We collect your personal information from others, such as
                    credit bureaus, affiliates or other companies
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.frameDiv9}>
              <div className={styles.frameDiv61}>
                <b className={styles.howDoesPSIProtectMyPerson}>
                  Why can’t I limit all sharing?
                </b>
              </div>
              <div className={styles.frameDiv73}>
                <div className={styles.howDoesPSIProtectMyPerson}>
                  <p className={styles.theTypesOf}>
                    <span>Federal law gives you the right to limit only</span>
                  </p>
                  <p className={styles.theTypesOf}>
                    <span>&nbsp;</span>
                  </p>
                  <ul className={styles.sharingForAffiliateseveryda}>
                    <li className={styles.socialSecurityNumber}>
                      <span>
                        sharing for affiliates’everyday business
                        purposes—information about your credit worthiness 
                      </span>
                    </li>
                    <li className={styles.socialSecurityNumber}>
                      <span>
                        affiliates from using your information to market to you 
                      </span>
                    </li>
                    <li className={styles.socialSecurityNumber}>
                      <span>sharing for non affiliates to market to you</span>
                    </li>
                  </ul>
                  <p className={styles.theTypesOf}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.whenYouAre}>
                    <span>
                      State laws and individual companies may give you
                      additional rights to limit sharing.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameDiv56}>
          <div className={styles.frameDiv5}>
            <div className={styles.frameDiv58}>
              <div className={styles.frameDiv59}>
                <div className={styles.whoWeAre}>what we do</div>
              </div>
            </div>
            <div className={styles.frameDiv9}>
              <div className={styles.frameDiv61}>
                <b className={styles.howDoesPSIProtectMyPerson}>Affiliates</b>
              </div>
              <div className={styles.frameDiv70}>
                <div className={styles.howDoesPSIProtectMyPerson}>
                  <p className={styles.theTypesOf}>
                    Companies related by common ownership or control. They can
                    be financial and nonfinancial companies.
                  </p>
                  <p className={styles.theTypesOf}>&nbsp;</p>
                  <ul className={styles.sharingForAffiliateseveryda}>
                    <li>
                      Our affiliates include Brightstone Real Estate Partners,
                      LLC.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.frameDiv9}>
              <div className={styles.frameDiv61}>
                <b className={styles.howDoesPSIProtectMyPerson}>
                  Nonaffiliates
                </b>
              </div>
              <div className={styles.frameDiv70}>
                <div className={styles.howDoesPSIProtectMyPerson}>
                  <p className={styles.theTypesOf}>
                    Companies not related by common ownership or control. They
                    can be financial and nonfinancial companies.
                  </p>
                  <p className={styles.theTypesOf}>&nbsp;</p>
                  <ul className={styles.sharingForAffiliateseveryda}>
                    <li>
                      PSI does not share with nonaffiliates so they can market
                      to you
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.frameDiv9}>
              <div className={styles.frameDiv61}>
                <b className={styles.howDoesPSIProtectMyPerson}>
                  Joint marketing
                </b>
              </div>
              <div className={styles.frameDiv70}>
                <div className={styles.howDoesPSIProtectMyPerson}>
                  <p className={styles.theTypesOf}>
                    A formal agreement between nonaffiliated financial companies
                    that together market financial products or services to you.
                  </p>
                  <p className={styles.theTypesOf}>&nbsp;</p>
                  <ul className={styles.sharingForAffiliateseveryda}>
                    <li>PSI does not jointly market</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.frameDiv58}>
              <div className={styles.frameDiv59}>
                <div className={styles.whoWeAre}>
                  MORE IMPORTANT INFORMATION
                </div>
              </div>
            </div>
            <div className={styles.frameDiv89}>
              <div className={styles.frameDiv90}>
                <div className={styles.frameDiv91}>
                  <b className={styles.b}>-</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
};

export default PrivacyPolicy;
