import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import HOMEPAGE from "./pages/HOMEPAGE";
import OurProcessPage from "./pages/OurProcessPage";
import ContactUs from "./pages/ContactUs";
import ContactUsThanks from "./pages/ContactUsThanks";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useEffect } from "react";
import TermsAndConditions from "./pages/TermsAndConditions";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action]);

  useEffect(() => {
    let title = "Portfolio Strategies";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Portfolio Strategies";
        metaDescription = "";
        break;
      case "/our-process-page":
        title = "Portfolio Strategies | Our Process";
        metaDescription = "";
        break;
      case "/contact-us":
        title = "Portfolio Strategies | Contact Us";
        metaDescription = "";
        break;
      case "/contact-us-thanks":
        title = "Portfolio Strategies | Contact Us Thanks";
        metaDescription = "";
        break;
      case "/about-us":
        title = "Portfolio Strategies | About Us";
        metaDescription = "";
        break;
      case "/privacy-policy":
        title = "Portfolio Strategies | Privacy Policy";
        metaDescription = "";
        break;
      case "/terms-and-conditions":
        title = "Portfolio Strategies | Terms and Conditions";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HOMEPAGE />} />

      <Route path="/our-process-page" element={<OurProcessPage />} />

      <Route path="/contact-us" element={<ContactUs />} />

      <Route path="/contact-us-thanks" element={<ContactUsThanks />} />

      <Route path="/about-us" element={<AboutUs />} />

      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

    </Routes>
  );
}
export default App;
