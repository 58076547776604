import {FunctionComponent, useState, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import MenuPS from "../components/MenuPS";
import PortalPopup from "../components/PortalPopup";
import styles from "./OurProcessPage.module.css";
import Navbar from "../components/Navbar";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import {motion, Variants} from "framer-motion";

const OurProcessPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isMenuPSOpen, setMenuPSOpen] = useState(false);

  const [isQ1Open, setQ1Open] = useState(false);
  const [isQ2Open, setQ2Open] = useState(false);
  const [isQ3Open, setQ3Open] = useState(false);
  const [isQ4Open, setQ4Open] = useState(false);
  const [isQ5Open, setQ5Open] = useState(false);
  const [isQ6Open, setQ6Open] = useState(false);

  const onEllipseIcon4Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='ourProcessContainer33']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onEllipseIcon5Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='ourProcessContainer32']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onEllipseIcon6Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='ourProcessContainer34']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onEllipseIcon7Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='ourProcessContainer30']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onEllipseIcon8Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='container6']");
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onEllipseIcon9Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='ourProcessContainer34']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onEllipseIcon10Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='ourProcessContainer30']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onEllipseIcon11Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='ourProcessContainer33']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onEllipseIcon12Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='ourProcessContainer34']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onEllipseIcon13Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='ourProcessContainer30']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onEllipseIcon14Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='ourProcessContainer33']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onEllipseIcon15Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='ourProcessContainer32']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onFrameButtonClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onFrameButton1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const cardVariants: Variants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 1.2
      }
    }
  };

  return (
    <>
      <div className={styles.ourProcessPage}>
        <Navbar/>
        <div
          className={styles.ourProcessDiv}
          data-scroll-to="ourProcessBanner"
          id="ourProcessBanner"
        >
          <div className={styles.frameDiv4} data-animate-on-scroll>
            <div className={styles.resourcedDirectEfficientDiv}>
              Resourced—Direct—Efficient
            </div>
            <div className={styles.ourProcessDiv1}>Our Process</div>
            <div className={styles.weMakeItSimpleToBeginEar}>
              We make it simple to begin earning.
            </div>
          </div>
        </div>
        <div
          className={styles.ourProcessDiv2}
          data-scroll-to="ourOverview"
          id="ourOverview" data-animate-on-scroll
        >
          <div className={styles.div}>
            <div className={styles.frameDiv5}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once: true, amount: 0.8}}
              >
                <motion.div className="card" variants={cardVariants}>
                  <img
                    className={styles.danielleCerulloBIZJRVBLfOMUIcon}
                    alt=""
                    src="../img/daniellecerullobizjrvblfomunsplash@2x.jpeg"
                  />
                </motion.div>
              </motion.div>

            </div>
            <div className={styles.frameDiv6}>
              <div className={styles.frameDiv7}>
                <div className={styles.div1}>
                  <div className={styles.div2}>1</div>
                </div>
                <div className={styles.text1}>
                  <div className={styles.initialInquiryDiv}>
                    Initial Inquiry
                  </div>
                  <div
                    className={styles.bodyItIsOurMissionToBu}
                  >{`Your initial contact by phone, form, or email begins a free consultation with one of our investment managers who will discuss your financial goals, explain more about the Portfolio Strategies approach, answer any questions you may have, and obtain basic information to submit an account application. `}</div>
                </div>
              </div>
            </div>
            <div className={styles.frameDiv8}>
              <div className={styles.rectangleDiv}/>
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon4Click}
              />
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon5Click}
              />
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon6Click}
              />
            </div>
          </div>
        </div>
        <div
          className={styles.ourProcessDiv2}
          data-scroll-to="ourProcessContainer33" data-animate-on-scroll
        >
          <div className={styles.div}>
            <div className={styles.frameDiv9}>
              <img
                className={styles.danielleCerulloBIZJRVBLfOMUIcon}
                alt=""
                src="../img/daniellecerullobizjrvblfomunsplash1@2x.jpg"
              />
            </div>
            <div className={styles.frameDiv10}>
              <div className={styles.frameDiv7}>
                <div className={styles.div1}>
                  <div className={styles.div2}>2</div>
                </div>
                <div className={styles.text1}>
                  <div
                    className={styles.initialInquiryDiv}
                  >{`Discovery & On-Boarding`}</div>
                  <div className={styles.bodyItIsOurMissionToBu}>
                    Once you have made a choice to begin investing with us, we
                    schedule a brief discovery meeting to get to know you and
                    the motivations, values, and goals that will guide our
                    investment decisions. When we receive notification from
                    Fidelity Investments that your account is open, we will
                    direct you on wire transfer details and begin trading on
                    your behalf.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameDiv12}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once: true, amount: 0.8}}
              >
                <motion.div className="card" variants={cardVariants}>
                  <img
                    className={styles.danielleCerulloBIZJRVBLfOMUIcon}
                    alt=""
                    src="../img/daniellecerullobizjrvblfomunsplash2@2x.jpg"
                  />
                </motion.div>
              </motion.div>
            </div>
            <div className={styles.frameDiv8}>
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon7Click}
              />
              <div className={styles.rectangleDiv}/>
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon8Click}
              />
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon9Click}
              />
            </div>
          </div>
        </div>
        <div
          className={styles.ourProcessDiv2}
          data-scroll-to="ourProcessContainer32" data-animate-on-scroll
        >
          <div className={styles.div} data-scroll-to="container6">
            <div className={styles.frameDiv5}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once: true, amount: 0.8}}
              >
                <motion.div className="card" variants={cardVariants}>
                  <img
                    className={styles.danielleCerulloBIZJRVBLfOMUIcon}
                    alt=""
                    src="../img/daniellecerullobizjrvblfomunsplash3@2x.jpeg"
                  />
                </motion.div>
              </motion.div>
            </div>
            <div className={styles.frameDiv6}>
              <div className={styles.frameDiv7}>
                <div className={styles.div1}>
                  <div className={styles.div2}>3</div>
                </div>
                <div className={styles.text12}>
                  <div
                    className={styles.planDevelopmentImplementat}
                  >{`Plan Development & Implementation`}</div>
                  <div
                    className={styles.bodyItIsOurMissionToBu}
                  >{`Based on your goals, we build a diversified portfolio and remain extremely active in your account. With our daily supervision on current investments and continual monitoring of trends, we make calculated determinations through a multi-step process with careful analysis on when, if, what, and where to trade. `}</div>
                </div>
              </div>
            </div>
            <div className={styles.frameDiv8}>
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon10Click}
              />
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon11Click}
              />
              <div className={styles.rectangleDiv}/>
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon12Click}
              />
            </div>
          </div>
        </div>
        <div
          className={styles.ourProcessDiv2}
          data-scroll-to="ourProcessContainer34" data-animate-on-scroll
        >
          <div className={styles.div}>
            <div className={styles.frameDiv9}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once: true, amount: 0.8}}
              >
                <motion.div className="card" variants={cardVariants}>
                  <img
                    className={styles.danielleCerulloBIZJRVBLfOMUIcon}
                    alt=""
                    src="../img/danielle-cerullo-bIZJRVBLfOM-unsplash.jpeg"
                  />
                </motion.div>
              </motion.div>
            </div>
            <div className={styles.frameDiv19}>
              <div className={styles.frameDiv7}>
                <div className={styles.div1}>
                  <div className={styles.div2}>4</div>
                </div>
                <div className={styles.text1}>
                  <div
                    className={styles.initialInquiryDiv}
                  >{`Continuous Monitoring & Reporting`}</div>
                  <div className={styles.bodyItIsOurMissionToBu}>
                    In addition to Fidelity’s monthly report, you will receive
                    notice before and after every transaction and a personalized
                    statement from us every quarter. At any time, we are always
                    only a phonecall or email away to discuss your account and
                    answer any questions you may have about investing.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameDiv21}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once: true, amount: 0.8}}
              >
                <motion.div className="card" variants={cardVariants}>
                  <img
                    className={styles.danielleCerulloBIZJRVBLfOMUIcon}
                    alt=""
                    src="../img/danielle-cerullo-bIZJRVBLfOM-unsplash.jpeg"
                  />
                </motion.div>
              </motion.div>
            </div>
            <div className={styles.frameDiv8}>
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon13Click}
              />
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon14Click}
              />
              <img
                className={styles.ellipseIcon4}
                alt=""
                src="../svg/ellipse-5.svg"
                onClick={onEllipseIcon15Click}
              />
              <div className={styles.rectangleDiv}/>
            </div>
          </div>
        </div>
        <div className={styles.ourProcessDiv6} data-animate-on-scroll
        >

          <button className={styles.frameButton} onClick={onFrameButtonClick}>
            <div className={styles.bodyItIsOurMissionToBu4}>
              SPEAK WITH CONCIERGE
            </div>
          </button>
        </div>

        <div className={styles.frameDiv23}
             data-scroll-to="faq"
             id="faq" data-animate-on-scroll
        >
          <div className={styles.frameDiv24}>
            <div className={styles.frameDiv25}>
              <div className={styles.frequentlyAskedQuestions}>
                Frequently Asked Questions
              </div>
              <div className={styles.bodyItIsOurMissionToBu}>
                Choosing an investment advisor involves many questions. We hope
                to answer some common ones here and invite you to please contact
                us directly.
              </div>

              <button
                className={styles.frameButton1}
                onClick={onFrameButton1Click}
              >
                <div className={styles.bodyItIsOurMissionToBu5}>
                  Contact us for a free consultation
                </div>
              </button>
            </div>
            <div className={styles.frameDiv26}>
              <div className={styles.lineDiv}/>
              <div className={styles.frameDiv27}>
                <div className={styles.whatIsTheMinimumAmountOf}>
                  What is the minimum amount of time recommended for investment?
                </div>
                <img onClick={() => setQ1Open(!isQ1Open)} className={styles.icon} alt=""
                     src={isQ1Open ? "../svg/icon10.svg" : "../svg/icon5.svg"}/>
              </div>
              {isQ1Open ? (<>
                <div className={styles.moreQuestion}>
                  <span>We generally structure portfolios to perform over the long term. An investment horizon of at least
                  three years is recommended.</span>
                </div>
              </>) : (<></>)}
              <div className={styles.lineDiv}/>
              <div className={styles.frameDiv28}>
                <div className={styles.whatIsTheMinimumAmountOf}>
                  What services do you provide?
                </div>
                <img onClick={() => setQ2Open(!isQ2Open)} className={styles.icon} alt=""
                     src={isQ2Open ? "../svg/icon10.svg" : "../svg/icon5.svg"}/>
              </div>
              {isQ2Open ? (<>
                <div className={styles.moreQuestion}>
                <span>We offer solid investing based on years of market experience. Portfolios are invested in curated
                  market holdings designed to maximize your financial goals.</span>
                </div>
              </>) : (<></>)}
              <div className={styles.lineDiv}/>
              <div className={styles.frameDiv29}>
                <div className={styles.whatIsTheMinimumAmountOf}>
                  How do your fees work?
                </div>
                <img onClick={() => setQ3Open(!isQ3Open)} className={styles.icon} alt=""
                     src={isQ3Open ? "../svg/icon10.svg" : "../svg/icon5.svg"}/>
              </div>
              {isQ3Open ? (<>
                <div className={styles.moreQuestion}>
                <span>We charge an asset-based management fee. We do not receive commissions or transaction fees. Fees
                  are assessed quarterly and can be deducted directly from your account.</span>
                </div>
              </>) : (<></>)}
              <div className={styles.lineDiv}/>
              <div className={styles.frameDiv30}>
                <div className={styles.whatIsTheMinimumAmountOf}>
                  What is your minimum investment amount?
                </div>
                <img onClick={() => setQ4Open(!isQ4Open)} className={styles.icon} alt=""
                     src={isQ4Open ? "../svg/icon10.svg" : "../svg/icon5.svg"}/>
              </div>
              {isQ4Open ? (<>
                <div className={styles.moreQuestion}>
                <span> We manage portfolios ranging from several thousand to many
                      millions of dollars. Regardless of the size, all accounts
                      get our full attention, valued as part of your financial
                      future.</span>
                </div>
              </>) : (<></>)}
              <div className={styles.lineDiv}/>
              <div className={styles.frameDiv31}>
                <div className={styles.whatIsTheMinimumAmountOf}>
                  What type of accounts can you manage?
                </div>
                <img onClick={() => setQ5Open(!isQ5Open)} className={styles.icon} alt=""
                     src={isQ5Open ? "../svg/icon10.svg" : "../svg/icon5.svg"}/>
              </div>
              {isQ5Open ? (<>
                <div className={styles.moreQuestion}>
                <span>We manage accounts across the spectrum, including IRAs,
                      401-ks, corporate accounts, non-retirement accounts, and
                      everything in between.</span>
                </div>
              </>) : (<></>)}
              <div className={styles.lineDiv}/>
              <div className={styles.frameDiv32}>
                <div className={styles.whatIsTheMinimumAmountOf}>
                  Can I access my funds?
                </div>
                <img onClick={() => setQ6Open(!isQ6Open)} className={styles.icon} alt=""
                     src={isQ6Open ? "../svg/icon10.svg" : "../svg/icon5.svg"}/>
              </div>
              {isQ6Open ? (<>
                <div className={styles.moreQuestion}>
                <span>
              Investment funds always remain in your name and can be withdrawn at any time.</span>
                </div>
              </>) : (<></>)}
            </div>
          </div>
        </div>
        <ContactUs/>
        <Footer/>
      </div>
    </>
  );
};

export default OurProcessPage;
