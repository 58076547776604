import {FunctionComponent, useState, useCallback} from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MenuPS from "../components/MenuPS";
import PortalPopup from "../components/PortalPopup";
import styles from "./AboutUs.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {motion, Variants} from "framer-motion";

const AboutUs: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isMenuPSOpen, setMenuPSOpen] = useState(false);
  const [isOverrideMoreShowing, setOverrideMoreShowing] = useState(false);
  const [isFounderShowing, setFounderShowing] = useState(false);

  const [isMember1Visible, setMember1Visible] = useState(true);
  const [isMember2Visible, setMember2Visible] = useState(false);
  const [isMember3Visible, setMember3Visible] = useState(false);
  const [isMember4Visible, setMember4Visible] = useState(false);
  const [isMember5Visible, setMember5Visible] = useState(false);
  const [isMember6Visible, setMember6Visible] = useState(false);
  const [isMember7Visible, setMember7Visible] = useState(false);

  const closeMenuPS = useCallback(() => {
    setMenuPSOpen(false);
  }, []);

  const onButton1Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='frameContainer13']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const onPAULKIRSHENBAUMTextClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='frameContainer13']"
    );
    if (anchor) {
      anchor.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);

  const activeMember1 = useCallback(() => {
    setMember1Visible(true);
    setMember2Visible(false);
    setMember3Visible(false);
    setMember4Visible(false);
    setMember5Visible(false);
    setMember6Visible(false);
    setMember7Visible(false);
  }, []);

  const activeMember2 = useCallback(() => {
    setMember1Visible(false);
    setMember2Visible(true);
    setMember3Visible(false);
    setMember4Visible(false);
    setMember5Visible(false);
    setMember6Visible(false);
    setMember7Visible(false);
  }, []);

  const activeMember3 = useCallback(() => {
    setMember1Visible(false);
    setMember2Visible(false);
    setMember3Visible(true);
    setMember4Visible(false);
    setMember5Visible(false);
    setMember6Visible(false);
    setMember7Visible(false);
  }, []);

  const activeMember4 = useCallback(() => {
    setMember1Visible(false);
    setMember2Visible(false);
    setMember3Visible(false);
    setMember4Visible(true);
    setMember5Visible(false);
    setMember6Visible(false);
    setMember7Visible(false);
  }, []);

  const activeMember5 = useCallback(() => {
    setMember1Visible(false);
    setMember2Visible(false);
    setMember3Visible(false);
    setMember4Visible(false);
    setMember5Visible(true);
    setMember6Visible(false);
    setMember7Visible(false);
  }, []);

  const activeMember6 = useCallback(() => {
    setMember1Visible(false);
    setMember2Visible(false);
    setMember3Visible(false);
    setMember4Visible(false);
    setMember5Visible(false);
    setMember6Visible(true);
    setMember7Visible(false);
  }, []);

  const activeMember7 = useCallback(() => {
    setMember1Visible(false);
    setMember2Visible(false);
    setMember3Visible(false);
    setMember4Visible(false);
    setMember5Visible(false);
    setMember6Visible(false);
    setMember7Visible(true);
  }, []);

  const overviewMore = useCallback(() => {
    setOverrideMoreShowing(true);
  }, []);

  const overviewLess = useCallback(() => {
    setOverrideMoreShowing(false);
  }, []);


  const founderMore = useCallback(() => {
    setFounderShowing(true);
  }, []);

  const founderLess = useCallback(() => {
    setFounderShowing(false);
  }, []);

  const cardVariants: Variants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 1.2
      }
    }
  };

  return (
    <>
      <div className={styles.aboutUsDiv}>
        <Navbar/>
        <div className={styles.aboutUsNavBar}
             data-scroll-to="aboutUsBanner"
             id="aboutUsBanner"
        >
          <div className={styles.frameDiv4}>
            <div className={styles.frameDiv5}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once: true, amount: 0.8}}
              >
                <motion.div className="card" variants={cardVariants}>
                  <h1 className={styles.aboutUsH1}>About Us</h1>
                  <div className={styles.bodyItIsOurMissionToBu}>

                    <p
                      className={styles.portfolioStrategiesIs}
                    >{`Portfolio Strategies is an established wealth management firm serving clients with long term stock market investments. `}</p>
                    <p className={styles.portfolioStrategiesIs}>&nbsp;</p>
                    <p className={styles.ourProprietaryMethodology}>

                      Our proprietary methodology blends traditional and current
                      investment vehicles for a strong hybrid of active and passive
                      investing. Based on what is important to you, your goals, and
                      motivations—we invest using strategies positioned to perform
                      well regardless of market conditions and downturns.

                    </p>
                  </div>

                </motion.div>
              </motion.div>
            </div>
            <div className={styles.frameDiv6}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once: true, amount: 0.8}}
              >
                <motion.div className="card" variants={cardVariants}>
                  <img
                    className={styles.yibeiGengUdYbiywGegUnsplasIcon}
                    alt=""
                    src="../img/yibeigengudybiywgegunsplash-1@2x.jpg"
                  />
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
        <div className={styles.comapnyOverview}
             data-scroll-to="aboutUsOverview"
             id="aboutUsOverview"

        >
          <address className={styles.frameAddress}>
            <div className={styles.frameDiv7}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once: true, amount: 0.8}}
              >
                <motion.div className="card" variants={cardVariants}>
                  <img
                    className={styles.campaignCreatorsGMsnXqILjp4Icon}
                    alt=""
                    src="../img/campaigncreatorsunsplash-1@2x.jpeg"
                  />
                </motion.div>
              </motion.div>
            </div>
            <div className={styles.cOMPANYOVERVIEWSUBCONTAINER}>
              <div className={styles.frameDiv8}>
                <div className={styles.qualityCODiv}>
                  <img
                    className={styles.structure1Icon}
                    alt=""
                    src="../img/structure-14@2x.png"
                  />
                  <div className={styles.experienceStrategyInnovationDiv}>
                    Experience—Strategy—Innovation
                  </div>
                </div>
                <div className={styles.tittleAndText}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div className={styles.companyOverviewDiv}>
                        Company Overview
                      </div>
                      <div className={styles.bodyItIsOurMissionToBu}>
                        Portfolio Strategies is an independent U.S. Securities
                        Exchange Commission (SEC) Registered Investment Advisor
                        (RIA) based in Montvale, NJ. With 40 years of experience in
                        the markets and ongoing strategic innovation, we manage our
                        client’s wealth in alignment with their goals and
                        objectives.
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              {!isOverrideMoreShowing ? (<>
                <div className={styles.readMoreDiv}>
                  <a className={styles.frameDiv9} onClick={overviewMore}>
                    <div className={styles.rEADMOREDiv}>READ MORE</div>
                    <img className={styles.icon} alt="" src="../svg/icon.svg"/>
                  </a>
                </div>
              </>) : (<></>)}
              <div>

              </div>
            </div>
          </address>
          {isOverrideMoreShowing ? (<>
            <address className={styles.frameAddress2}>

              <div className={styles.cOMPANYOVERVIEWSUBCONTAINER}>
                <div className={styles.frameDiv8}>
                  <div className={styles.tittleAndText}>
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{once: true, amount: 0.8}}
                    >
                      <motion.div className="card" variants={cardVariants}>
                        <div className={styles.bodyItIsOurMissionToBu2}>
                          As fiduciary investors, we trade on our client’s behalf; we do not maintain custody of client
                          assets. All client investment portfolios are held with Fidelity Investments, a
                          highly-regarded,
                          industry-leading financial institution.
                          <br/>
                          <br/>
                          Our Fee-Only structure is the most transparent and objective method of compensation which
                          guarantees
                          our fiduciary responsibility to manage investments in the best interest of our clients.
                        </div>
                      </motion.div>
                    </motion.div>
                  </div>
                </div>
                <div className={styles.readLessDiv}>
                  <div className={styles.frameDiv9} onClick={overviewLess}>
                    <div className={styles.rEADMOREDiv}>READ Less</div>
                    <img className={styles.icon} style={{
                      padding: '5px'
                    }} alt="" src="../svg/up_arrow.svg"/>
                  </div>
                </div>
                <div>

                </div>
              </div>
              <div className={styles.frameDiv7}>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{once: true, amount: 0.8}}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <img
                      className={styles.campaignCreatorsGMsnXqILjp4Icon2}
                      alt=""
                      src="../img/campaign-creators-gMsnXqILjp4-unsplash 1.jpeg"
                    />
                  </motion.div>
                </motion.div>
              </div>
            </address>
          </>) : (<></>)}

        </div>
        <div className={styles.frameDiv10}
             data-scroll-to="ourHistory"
             id="ourHistory"
        >
          {isOverrideMoreShowing ? (<>
            <div style={{height: '90px'}}></div>
          </>) : (<></>)}
          <div className={styles.frameDiv11}>
            <div className={styles.frameDiv12}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once: true, amount: 0.8}}
              >
                <motion.div className="card" variants={cardVariants}>
                  <div className={styles.frameDiv13}>
                    <div className={styles.establishedStableProsperousDiv}>
                      Established—Stable—Prosperous

                    </div>
                    <h1 className={styles.ourHistoryH1}>Our History</h1>
                  </div>
                  <div className={styles.bodyItIsOurMissionToBu1}>
                    Industry veteran and visionary Paul Kirshenbaum, PhD began his
                    career as an investment advisor more than 40 years ago. Guided
                    by extensive market insight and decades-long learning, Portfolio
                    Strategies continues to grow organically, building upon the
                    success of the firm’s steady returns with an innovative strategy
                    that combines older and newer investment vehicles.
                  </div>
                </motion.div>
              </motion.div>
            </div>
            <motion.div
              className="card-container"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{once: true, amount: 0.8}}
            >
              <motion.div className="card" variants={cardVariants}>
                <button className={styles.button1} onClick={onButton1Click}>
                  <div className={styles.unstyledButtonDiv}>
                    <div className={styles.buttonDiv}>
                      Learn more about Dr. Kirshenbaum
                    </div>
                  </div>
                </button>
              </motion.div>
            </motion.div>
          </div>
        </div>
        <div className={styles.frameDiv14}
             data-scroll-to="ourValues"
             id="ourValues"
        >
          <div className={styles.frameDiv15}>
            <div className={styles.frameDiv16}>
              <div className={styles.frameDiv17}>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{once: true, amount: 0.8}}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <div className={styles.qualityCODiv}>
                      <div className={styles.frameDiv19}>
                        <img
                          className={styles.structure1Icon1}
                          alt=""
                          src="../img/structure-15@2x.png"
                        />
                      </div>
                      <div className={styles.commitmentIntegritySimplicitDiv}>
                        Commitment—Integrity—Simplicity
                      </div>
                    </div>
                    <div className={styles.frameDiv20}>
                      <div className={styles.ourValuesDiv}>Our Values</div>
                      <div className={styles.weAreGuidedByCommonPrinci}>
                        We are guided by common principles that define our approach
                        and our success.
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              </div>
              <div className={styles.frameDiv21}>
                <div className={styles.frameDiv22}>

                  {/*structure*/}
                  <div className={styles.disciplineDiv}>
                    <div className={styles.frameDiv24}>
                      <motion.div
                        className="card-container"
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{once: true, amount: 0.8}}
                      >
                        <motion.div className="card" variants={cardVariants}>
                          <img
                            className={styles.structure1Icon2}
                            alt=""
                            src="../img/structure-16@2x.png"
                          />
                        </motion.div>
                      </motion.div>
                    </div>
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{once: true, amount: 0.8}}
                    >
                      <motion.div className="card" variants={cardVariants}>
                        <div className={styles.structureDiv}>Structure</div>
                      </motion.div>
                    </motion.div>
                  </div>
                  {/*Responsiveness*/}
                  <div className={styles.disciplineDiv}>
                    <div className={styles.frameDiv24}>
                      <motion.div
                        className="card-container"
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{once: true, amount: 0.8}}
                      >
                        <motion.div className="card" variants={cardVariants}>
                          <img
                            className={styles.component3Icon}
                            alt=""
                            src="../svg/component-33.svg"
                          />
                        </motion.div>
                      </motion.div>
                    </div>
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{once: true, amount: 0.8}}
                    >
                      <motion.div className="card" variants={cardVariants}>
                        <div className={styles.responsivenessDiv}>
                          Responsiveness
                        </div>
                      </motion.div>
                    </motion.div>
                  </div>
                  {/*Communication*/}
                  <div className={styles.disciplineDiv}>
                    <div className={styles.frameDiv24}>
                      <motion.div
                        className="card-container"
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{once: true, amount: 0.8}}
                      >
                        <motion.div className="card" variants={cardVariants}>
                          <img
                            className={styles.structure1Icon3}
                            alt=""
                            src="../img/structure-17@2x.png"
                          />
                        </motion.div>
                      </motion.div>
                    </div>
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{once: true, amount: 0.8}}
                    >
                      <motion.div className="card" variants={cardVariants}>
                        <div className={styles.structureDiv}>Communication</div>
                      </motion.div>
                    </motion.div>
                  </div>
                </div>
                <div className={styles.frameDiv29}>
                  {/*discipline*/}
                  <div className={styles.disciplineDiv}>

                    <div className={styles.frameDiv30}>
                      <motion.div
                        className="card-container"
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{once: true, amount: 0.8}}
                      >
                        <motion.div className="card" variants={cardVariants}>
                          <img
                            className={styles.structure2Icon}
                            alt=""
                            src="../img/structure-23@2x.png"
                          />
                        </motion.div>
                      </motion.div>
                    </div>
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{once: true, amount: 0.8}}
                    >
                      <motion.div className="card" variants={cardVariants}>
                        <div className={styles.structureDiv}>Discipline</div>
                      </motion.div>
                    </motion.div>

                  </div>
                  {/*transparency*/}
                  <div className={styles.disciplineDiv}>
                    <div className={styles.frameDiv24}>
                      <motion.div
                        className="card-container"
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{once: true, amount: 0.8}}
                      >
                        <motion.div className="card" variants={cardVariants}>
                          <img
                            className={styles.frameIcon}
                            alt=""
                            src="../svg/frame4.svg"
                          />
                        </motion.div>
                      </motion.div>
                    </div>
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{once: true, amount: 0.8}}
                    >
                      <motion.div className="card" variants={cardVariants}>
                        <div className={styles.structureDiv}>Transparency</div>
                      </motion.div>
                    </motion.div>
                  </div>
                  {/*analysis*/}
                  <div className={styles.disciplineDiv}>
                    <div className={styles.frameDiv24}>
                      <motion.div
                        className="card-container"
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{once: true, amount: 0.8}}
                      >
                        <motion.div className="card" variants={cardVariants}>
                          <img
                            className={styles.structure1Icon3}
                            alt=""
                            src="../img/structure-18@2x.png"
                          />
                        </motion.div>
                      </motion.div>
                    </div>
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{once: true, amount: 0.8}}
                    >
                      <motion.div className="card" variants={cardVariants}>
                        <div className={styles.structureDiv}>Analysis</div>
                      </motion.div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.iMGANDCARDS}>
              <div className={styles.frameDiv33}>
                {/*Accountability*/}
                <div className={styles.honestyCardDiv}>
                  <div className={styles.disciplineDiv}>
                    <div className={styles.frameDiv24}>
                      <motion.div
                        className="card-container"
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{once: true, amount: 0.8}}
                      >
                        <motion.div className="card" variants={cardVariants}>
                          <img
                            className={styles.structure1Icon3}
                            alt=""
                            src="../img/structure-110@2x.png"
                          />
                        </motion.div>
                      </motion.div>
                    </div>
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{once: true, amount: 0.8}}
                    >
                      <motion.div className="card" variants={cardVariants}>
                        <div className={styles.structureDiv}>Accountability</div>
                      </motion.div>
                    </motion.div>
                  </div>
                </div>
                <div className={styles.honestyCardDiv}>
                  <div className={styles.disciplineDiv}>
                    <div className={styles.frameDiv24}>
                      <motion.div
                        className="card-container"
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{once: true, amount: 0.8}}
                      >
                        <motion.div className="card" variants={cardVariants}>
                          <img
                            className={styles.structure1Icon3}
                            alt=""
                            src="../img/structure-19@2x.png"
                          />
                        </motion.div>
                      </motion.div>
                    </div>
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{once: true, amount: 0.8}}
                    >
                      <motion.div className="card" variants={cardVariants}>
                        <div className={styles.structureDiv}>Honesty</div>
                      </motion.div>
                    </motion.div>
                  </div>
                </div>

              </div>
              <div className={styles.frameDiv36}>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{once: true, amount: 0.8}}
                >
                  <motion.div className="card" variants={cardVariants}>
                    <img
                      className={styles.highRiseBuildingWithGlassIcon}
                      alt=""
                      src="../img/highrisebuildingwithglasswindowssunlight-1@2x.jpg"
                    />
                  </motion.div>
                </motion.div>
              </div>
              <div className={styles.frameDiv37}>
                <div className={styles.honestyCardDiv}>
                  <div className={styles.disciplineDiv}>
                    <div className={styles.frameDiv24}>
                      <motion.div
                        className="card-container"
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{once: true, amount: 0.8}}
                      >
                        <motion.div className="card" variants={cardVariants}>
                          <img
                            className={styles.structure1Icon3}
                            alt=""
                            src="../img/structure-19@2x.png"
                          />
                        </motion.div>
                      </motion.div>
                    </div>
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{once: true, amount: 0.8}}
                    >
                      <motion.div className="card" variants={cardVariants}>
                        <div className={styles.honestyDiv1}>Honesty</div>
                      </motion.div>
                    </motion.div>
                  </div>
                </div>
                <div className={styles.honestyCardDiv}>
                  <div className={styles.disciplineDiv}>
                    <div className={styles.frameDiv24}>
                      <motion.div
                        className="card-container"
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{once: true, amount: 0.8}}
                      >
                        <motion.div className="card" variants={cardVariants}>
                          <img
                            className={styles.structure1Icon3}
                            alt=""
                            src="../img/structure-110@2x.png"
                          />
                        </motion.div>
                      </motion.div>
                    </div>
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{once: true, amount: 0.8}}
                    >
                      <motion.div className="card" variants={cardVariants}>
                        <div className={styles.honestyDiv1}>Accountability</div>
                      </motion.div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameDiv40}
             data-scroll-to="ourTeam"
             id="ourTeam"
        >
          <div className={styles.brownFrame}>
            <div className={styles.leftSideDiv}>
              <div className={styles.frameDiv41}>
                <div className={styles.qualityCODiv}>

                  <img
                    className={styles.structure1Icon9}
                    alt=""
                    src="../img/structure-113@2x.png"
                  />
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div className={styles.educatedInspiredFocusedDiv}>
                        Educated—Inspired—Focused
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
                <div className={styles.tittleAndText}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div className={styles.ourTeamDiv}>Our Team</div>
                      <div className={styles.bodyItIsOurMissionToBu}>
                        An expert team dedicated to providing boutique-level service
                        with the investment advisory experience, insight, and
                        professionalism to maximize your financial goals.
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              <div className={styles.nAMESDiv}>
                <div className={styles.inputDiv}>
                  <FormControl  variant="filled" className={styles.selectTeamMobile}>
                    <InputLabel color="primary">Our Team</InputLabel>
                    <Select color="primary" size="medium" label="PAUL KIRSHENBAUM" className={styles.selectTeamMobile} defaultValue={"PAUL KIRSHENBAUM"}>
                      <MenuItem value="PAUL KIRSHENBAUM"  onClick={activeMember1} selected>
                        PAUL KIRSHENBAUM
                      </MenuItem>
                      <MenuItem value="ZEV KATZ"  onClick={activeMember2}>ZEV KATZ</MenuItem>
                      <MenuItem value="YISROEL SPIEGEL"  onClick={activeMember3}>
                        YISROEL SPIEGEL
                      </MenuItem>
                      <MenuItem value="NAFTALI SAVITZ"  onClick={activeMember4}>NAFTALI SAVITZ</MenuItem>
                      <MenuItem value="MIRIAM GERETZ">MIRIAM GERETZ</MenuItem>
                      <MenuItem value="ELI NEIMAN">ELI NEIMAN</MenuItem>
                      <MenuItem value="RIVKY SPITZER">RIVKY SPITZER</MenuItem>
                    </Select>
                    <FormHelperText/>
                  </FormControl>
                </div>
                <div className={styles.frameDiv42}>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div
                        className={styles.pAULKIRSHENBAUMDiv + (isMember1Visible ? " " + styles.memberActive : "")}
                        onClick={activeMember1}
                      >
                        PAUL KIRSHENBAUM
                      </div>

                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div
                        className={styles.zevKatzDiv + (isMember2Visible ? " " + styles.memberActive : "")}
                        onClick={activeMember2}
                      >
                        Zev Katz
                      </div>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div
                        className={styles.zevKatzDiv + (isMember3Visible ? " " + styles.memberActive : "")}
                        onClick={activeMember3}
                      >
                        Yisroel Spiegel
                      </div>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div
                        className={styles.zevKatzDiv + (isMember4Visible ? " " + styles.memberActive : "")}
                        onClick={activeMember4}
                      >
                        Naftali Savitz
                      </div>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div
                        className={styles.miriamGeretzDiv + (isMember5Visible ? " " + styles.memberActive : "")}>Miriam
                        Geretz
                      </div>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div className={styles.miriamGeretzDiv + (isMember6Visible ? " " + styles.memberActive : "")}>Eli
                        Neiman
                      </div>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                  >
                    <motion.div className="card" variants={cardVariants}>
                      <div
                        className={styles.miriamGeretzDiv + (isMember7Visible ? " " + styles.memberActive : "")}>Rivky
                        Spitzer
                      </div>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameDiv43} data-scroll-to="frameContainer13">
            {isMember1Visible ? (<>
              <div className={styles.bioDiv}>
                <div className={styles.nAMEANDPOSITION}>
                  <div
                    className={styles.founderActingPresident}
                  >{`Founder & Acting President`}</div>
                  <div className={styles.paulKirshenbaumDiv}>
                    Paul Kirshenbaum
                  </div>
                </div>
                <div className={styles.frameDiv44}>
                  <div className={styles.drKirschenbaumFoundedPortf}>
                    <p className={styles.portfolioStrategiesIs}>
                      Dr. Kirschenbaum founded Portfolio Strategies in 1993 and
                      has continued as its President for over 39 years, providing
                      and directing portfolio management and investment advisory
                      services for a variety of clients including individuals,
                      corporations, trusts, retirement plans, and non-profit
                      organizations.
                    </p>
                    <p className={styles.portfolioStrategiesIs}>&nbsp;</p>
                    <p className={styles.ourProprietaryMethodology}>
                      Dr. Kirshenbaum holds a BA from Brooklyn College, graduating
                      Magna Cum Laude With Honors in Mathematics and Economics, a
                      MS in Mathematics from NYU Courant Institute of Mathematical
                      Sciences, and a PhD in Economics from the NYU Stern School
                      of Business. After acquiring his PhD, he became a full-time
                      professor of economics and statistics, teaching primarily at
                      the graduate level. He took early retirement in 1992 and
                      founded Portfolio Strategies in 1993.
                    </p>
                    {isFounderShowing ? (<>
                      <p className={styles.portfolioStrategiesIs}>
                        In conjunction with his team, Dr. Kirshenbaum developed an investment system with component
                        procedures that has proven successful for achieving highly satisfactory investment results. In
                        2000 he began design and development of a complex and comprehensive computer software that is
                        used
                        daily by Portfolio Strategies in investment analysis and management.
                      </p>
                      <p className={styles.portfolioStrategiesIs}>
                        Not only is he regularly adding new modules and enhancement features to his proprietary
                        software,
                        he is continually updating and improving his fluid investment system, adding elements over time
                        that enhance the team’s ability to provide exceptional returns. He continues to be involved in
                        business operations on a daily basis with investment decisions and processes for individuals and
                        groups of portfolios alike.
                      </p>
                      <button className={styles.readMoreButton} onClick={founderLess}
                              style={{marginLeft: "-5px", marginTop: "25px"}}>
                        <div className={styles.rEADMOREDiv1}>READ less</div>
                        <img className={styles.icon} style={{
                          padding: '5px'
                        }} alt="" src="../svg/up_arrow_b.svg"/>
                      </button>
                      <div style={{height: "60px"}}></div>
                    </>) : (<>
                      <button className={styles.readMoreButton} onClick={founderMore}
                              style={{marginLeft: "-5px", marginTop: "25px"}}>
                        <div className={styles.rEADMOREDiv1}>READ MORE</div>
                        <img className={styles.icon} alt="" src="../svg/icon48.svg"/>
                      </button>
                    </>)}
                  </div>
                </div>

              </div>
            </>) : (<></>)}
            {isMember2Visible ? (<>
              <div className={styles.bioDiv}>
                <div className={styles.nAMEANDPOSITION}>
                  <div className={styles.founderActingPresident}>
                    Chief Operating Officer
                  </div>
                  <div className={styles.paulKirshenbaumDiv}>Zev Katz</div>
                </div>
                <div className={styles.frameDiv44}>
                  <div className={styles.mrKatzLeadsThePortfolioS}>
                    In his position as Chief Operating Officer, Zev Katz leads the Portfolio Strategies team with clear
                    thinking and exceptional problem-solving skills. In addition to managing the administrative side of
                    the firm, Zev is part of the investment team and works directly with clients to ensure a smooth
                    investment experience.
                    <br/>
                    <br/>
                    Zev has a master’s degree in computer science, and has many years experience creating state of the
                    art software and designing sophisticated database architectures. He has worked closely with Paul
                    Kirshenbaum for the past ten years to produce customized analytical and reporting software that we
                    use to perfect our investment strategies.
                  </div>
                </div>
              </div>
            </>) : (<></>)}
            {isMember3Visible ? (<>
              <div className={styles.bioDiv}>
                <div className={styles.nAMEANDPOSITION}>
                  <div className={styles.founderActingPresident}>
                    Investment Manager
                  </div>
                  <div className={styles.paulKirshenbaumDiv}>Yisroel Spiegel</div>
                </div>
                <div className={styles.frameDiv44}>
                  <div className={styles.mrSpiegelConductsExtensive}>
                    <p className={styles.portfolioStrategiesIs}>
                      Mr. Spiegel conducts extensive research to choose funds and
                      equities that align with the Portfolio Strategies investment
                      approach. With natural analytical abilities and a good head
                      for numbers, Yisroel works closely with Dr. Kirshenbaum on
                      the daily review of portfolios and on the decision-making
                      process for new investing.
                    </p>
                    <p className={styles.portfolioStrategiesIs}>
                      Yisroel is Series 65 certified, possessing detailed
                      knowledge of the various types of investments available and
                      all relevant laws, regulations, and ethics. In addition to
                      working directly on investment decisions globally and for
                      specific accounts, he dialogues with individual clients,
                      guiding them on appropriate investment tracks that align
                      with their changing needs and financial status.
                    </p>
                  </div>
                </div>
              </div>
            </>) : (<></>)}
            {isMember4Visible ? (<>
              <div className={styles.bioDiv}>
                <div className={styles.nAMEANDPOSITION}>
                  <div className={styles.founderActingPresident}>
                    Director of Business Development
                  </div>
                  <div className={styles.paulKirshenbaumDiv}>Naftali Savitz</div>
                </div>
                <div className={styles.frameDiv44}>
                  <div className={styles.mrSavitzHasBeenInterested}>
                    <p className={styles.portfolioStrategiesIs}>
                      Mr. Savitz has been interested in the power of investing
                      from a young age, and his own forays into the real estate
                      sector have given him a unique client-level view of the
                      portfolio management industry.
                    </p>
                    <p className={styles.portfolioStrategiesIs}>&nbsp;</p>
                    <p className={styles.portfolioStrategiesIs}>
                      Naftali’s distinctive combination of business experience,
                      intuitive people skills, and dedication to service has
                      allowed him to build successful relationships with
                      individuals and businesses. He excels in representing the
                      firm and continues to grow the client roster and encourage
                      existing clients to bring in new funds.
                    </p>
                    <p className={styles.portfolioStrategiesIs}>&nbsp;</p>
                    <p className={styles.ourProprietaryMethodology}>
                      Naftali takes a very hands-on role, following through with
                      each client to make sure their expectations are met with the
                      highest level of attention to detail. His commitment does
                      not end when the sale is made; Naftali stays in regular
                      contact with clients to anticipate their changing needs and
                      to keep them apprised of services offered.
                    </p>
                  </div>
                </div>
              </div>
            </>) : (<></>)}
          </div>
        </div>
        <Footer/>
      </div>
      {isMenuPSOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMenuPS}
        >
          <MenuPS onClose={closeMenuPS}/>
        </PortalPopup>
      )}
    </>
  );
};

export default AboutUs;
