import { FunctionComponent, useCallback, useState } from "react";
import styles from "../pages/HOMEPAGE.module.css";
import { useNavigate } from "react-router-dom";
import PortalPopup from "./PortalPopup";
import MenuPS from "./MenuPS";

const Navbar: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isMenuPSOpen, setMenuPSOpen] = useState(false);

  const onButtonClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const openMenuPS = useCallback(() => {
    setMenuPSOpen(true);
  }, []);

  const closeMenuPS = useCallback(() => {
    setMenuPSOpen(false);
  }, []);

  return (
    <>
      <div className={styles.navBarDiv}>
        <div className={styles.frameDiv}>
          <div className={styles.frameDiv1}>
            <a href="/">
              <img
                className={styles.psLogo06x2Icon}
                alt=""
                src="../svg/navbar-logo.svg"
              />
              <img
                src="../img/pslogo06-1@2x.png"
                className={styles.navbarLogo2}
              />
            </a>
          </div>
          <div className={styles.frameDiv2}>
            <button className={styles.button} onClick={onButtonClick}>
              <span className={styles.contactUsButton}>Contact Us</span>
            </button>
            <div className={styles.component2Div} onClick={openMenuPS}>
              <img
                className={styles.ellipseIcon}
                alt=""
                src="../svg/ham_white.svg"
              />
            </div>
          </div>
        </div>

        {isMenuPSOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top right"
            onOutsideClick={closeMenuPS}
          >
            <MenuPS onClose={closeMenuPS} />
          </PortalPopup>
        )}
      </div>
    </>
  );
};

export default Navbar;
