import styles from "../pages/HOMEPAGE.module.css";
import {TextField, ThemeProvider} from "@mui/material";
import {Parallax} from "react-parallax";

const ContactUs = () => {

  return (
    <Parallax bgImage="/img/contact-us@3x.jpg" bgImageAlt="the cat" strength={500}
              blur={{min: -15, max: 15}} style={{width: "100%"}}>
      <div
        className={styles.contactUsDiv}
        data-scroll-to="contactUsContainer"
        id="contactUsContainer"
      >
        <div className={styles.formHeaderDiv}>
          <div className={styles.formTitleSubtext}>Any questions?</div>
          <div className={styles.formTitleDiv}>Contact Us</div>
        </div>
          <form action="https://submit-form.com/Bddg0eeW" className={styles.formContact}>
          <div className={styles.frameDiv21}>
            <div className={styles.fillInYourDetailsToGetIn}>
              Fill in your details to get in contact!
            </div>
            <input
              type="hidden"
              name="_redirect"
              value="https://portfolio.da2go.io/contact-us-thanks"
              // value="http://localhost:3000/contact-us-thanks"
            />
            <div className={styles.frameDiv22}>
              <TextField
                className={styles.inputTextField}
                variant="outlined"
                type="text"
                label="Your name"
                size="medium"
                margin="none"
                name="name"
                InputLabelProps={{
                  style: {
                    color: 'var(--brown-10)'
                  }
                }}
                inputProps={{
                  style: {
                    color: 'var(--brown-10)'
                  },
                }}
              />
              <TextField
                className={styles.inputTextField}
                variant="outlined"
                type="text"
                label="Email address"
                size="medium"
                margin="none"
                name="email"
                InputLabelProps={{
                  style: {
                    color: 'var(--brown-10)'
                  }
                }}
                inputProps={{
                  style: {
                    color: 'var(--brown-10)'
                  }
                }}
              />
            </div>
            <button className={styles.button4}>
              <div className={styles.unstyledButtonDiv1}>
                <div className={styles.buttonDiv1}>submit</div>
              </div>
            </button>
          </div>
        </form>
        <div className={styles.contactUsDivLeftFixture}></div>
        <div className={styles.contactUsDivRightFixture}></div>
      </div>
    </Parallax>
  )
}

export default ContactUs;
