import {FunctionComponent, useCallback, useEffect, useState} from "react";
import styles from "../components/Footer.module.css";
import {useNavigate, useLocation} from "react-router-dom";


const Footer: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onHomeTextClick = useCallback(() => {
    if (location.pathname === '/') {
      const anchor = document.querySelector(
        "[data-scroll-to='bannerContaierContainer']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/#bannerContaierContainer");
    }

  }, []);

  const onPortfolioStrategiesTextClick = useCallback(() => {

    if (location.pathname === '/') {
      const anchor = document.querySelector(
        "[data-scroll-to='bannerContaierContainer']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/#bannerContaierContainer");
    }
  }, []);

  const onWhyPSTextClick = useCallback(() => {
    if (location.pathname === '/') {
      const anchor = document.querySelector(
        "[data-scroll-to='whyPortfolioContents']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/#whyPortfolioContents");
    }
  }, []);

  const onOurCompetitiveAvantage = useCallback(() => {
    if (location.pathname === '/') {
      const anchor = document.querySelector(
        "[data-scroll-to='ourCompetitiveAdvantage']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/#ourCompetitiveAdvantage");
    }
  }, []);

  const onOurApproach = useCallback(() => {
    if (location.pathname === '/') {
      const anchor = document.querySelector(
        "[data-scroll-to='ourApproach']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/#ourApproach");
    }
  }, []);

  const onContactUsTextClick = useCallback(() => {
    if (location.pathname === '/') {
      const anchor = document.querySelector(
        "[data-scroll-to='contactUsContainer']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/#contactUsContainer");
    }
  }, []);

  const onAboutUsContainerClick = useCallback(() => {

    if (location.pathname === '/about-us') {
      const anchor = document.querySelector(
        "[data-scroll-to='aboutUsBanner']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/about-us#aboutUsBanner");
    }
  }, [navigate]);

  const onAboutUsOverviewContainerClick = useCallback(() => {

    if (location.pathname === '/about-us') {
      const anchor = document.querySelector(
        "[data-scroll-to='aboutUsOverview']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/about-us#aboutUsOverview");
    }
  }, [navigate]);

  const onOurHistoryClick = useCallback(() => {

    if (location.pathname === '/about-us') {
      const anchor = document.querySelector(
        "[data-scroll-to='ourHistory']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/about-us#ourHistory");
    }
  }, [navigate]);

  const onOurValues = useCallback(() => {

    if (location.pathname === '/about-us') {
      const anchor = document.querySelector(
        "[data-scroll-to='ourValues']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/about-us#ourValues");
    }
  }, [navigate]);

  const onOurTeam = useCallback(() => {

    if (location.pathname === '/about-us') {
      const anchor = document.querySelector(
        "[data-scroll-to='ourTeam']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/about-us#ourTeam");
    }
  }, [navigate]);

  const onOurProcessContainerClick = useCallback(() => {
    if (location.pathname === '/our-process-page') {
      const anchor = document.querySelector(
        "[data-scroll-to='ourProcessBanner']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/our-process-page#ourProcessBanner");
    }
  }, [navigate]);

  const onOverviewClick = useCallback(() => {
    if (location.pathname === '/our-process-page') {
      const anchor = document.querySelector(
        "[data-scroll-to='ourOverview']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/our-process-page#ourOverview");
    }
  }, [navigate]);

  const onFAQClick = useCallback(() => {
    if (location.pathname === '/our-process-page') {
      const anchor = document.querySelector(
        "[data-scroll-to='faq']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/our-process-page#faq");
    }
  }, [navigate]);

  const onContactUsClick = useCallback(() => {
    if (location.pathname === '/our-process-page') {
      const anchor = document.querySelector(
        "[data-scroll-to='contactUsContainer']"
      );
      if (anchor) {
        anchor.scrollIntoView({block: "start", behavior: "smooth"});
      }
    } else {
      navigate("/our-process-page#contactUsContainer");
    }
  }, [navigate]);

  const onContactUsContainerClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onPrivacyPolicyTextClick = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onTermsAndConditionClick = useCallback(() => {
    navigate("/terms-and-conditions");
  }, [navigate]);

  useEffect(()=> {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({behavior: "smooth"})
      }
    } else {
      window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
  }, [location,])

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <>
      <div className={styles.footerSectionDiv}>
        <div className={styles.frameDiv23}>
          <div className={styles.frameDiv24}>
            <div className={styles.logoAndSocialMedia}>
              <div className={styles.logoDiv}>
                <div className={styles.groupDiv}>
                  <img
                    className={styles.psLogo061Icon}
                    alt=""
                    src="../img/pslogo06-1@2x.png"
                  />
                  <img
                    className={styles.psLogo031Icon}
                    alt=""
                    src="../img/pslogo03-1@2x.png"
                  />
                </div>
              </div>
              <div className={styles.socialIconsDiv}>
                <div className={styles.groupIcon}>
                  <a href="mailto:nsavitz@psiinvest.com">
                    <img
                      className={styles.shapeIcon}
                      alt=""
                      src="../svg/email_icon.svg"
                    />
                  </a>
                </div>
              </div>
              <div className={styles.socialIconsDiv1}>
                <div className={styles.groupIcon}>
                  <a href="mailto:nsavitz@psiinvest.com">
                    <img
                      className={styles.shapeIcon}
                      alt=""
                      src="../svg/email_icon.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.footerLinks}>
              <div className={styles.homeDiv}>
                <div className={styles.homeDiv1} onClick={onHomeTextClick}>
                  Home
                </div>
                <div
                  className={styles.portfolioStrategiesDiv}
                  onClick={onPortfolioStrategiesTextClick}
                >
                  Portfolio Strategies
                </div>
                <div
                  className={styles.portfolioStrategiesDiv}
                  onClick={onWhyPSTextClick}
                >
                  Why PS
                </div>
                <div className={styles.ourCompetitiveAvantage}
                     onClick={onOurCompetitiveAvantage}
                >
                  Our Competitive Avantage
                </div>
                <div className={styles.ourCompetitiveAvantage} onClick={onOurApproach}>
                  Our Approach
                </div>
                <div
                  className={styles.contactUsDiv1}
                  onClick={onContactUsTextClick}
                >
                  Contact Us
                </div>
              </div>
              <div
                className={styles.aboutUsDiv}
              >
                <div className={styles.aboutUsDiv1}
                  onClick={onAboutUsContainerClick}
                >About Us</div>
                <div className={styles.ourCompetitiveAvantage}
                  onClick={onAboutUsOverviewContainerClick}
                >Overview</div>
                <div className={styles.ourCompetitiveAvantage}
                  onClick={onOurHistoryClick}
                >History</div>
                <div className={styles.ourCompetitiveAvantage}
                  onClick={onOurValues}
                >Values</div>
                <div className={styles.ourCompetitiveAvantage}
                  onClick={onOurTeam}
                >Our Team</div>
              </div>
              <div
                className={styles.aboutUsDiv}

              >
                <div className={styles.aboutUsDiv1}
                     onClick={onOurProcessContainerClick}
                >Our Process</div>
                <div className={styles.ourCompetitiveAvantage}
                  onClick={onOurProcessContainerClick}
                >Overview</div>
                <div className={styles.ourCompetitiveAvantage}
                     onClick={onOverviewClick}
                >Process</div>
                <div className={styles.ourCompetitiveAvantage}
                  onClick={onFAQClick}
                >FAQs</div>
                {/*<div className={styles.ourCompetitiveAvantage}*/}
                {/*  onClick={onContactUsClick}*/}
                {/*>Contact Us</div>*/}
              </div>
              <div
                className={styles.aboutUsDiv}
                onClick={onContactUsContainerClick}
              >
                <div className={styles.aboutUsDiv1}>Contact Us</div>
                <div className={styles.ourCompetitiveAvantage}>Map</div>
                <div className={styles.ourCompetitiveAvantageage}>
                  Contact Us
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameDiv25}>
            <div className={styles.lineDiv}/>
          </div>
          <div className={styles.policiesDiv}>
            <div className={styles.pORTFOLIOSTRATEGIESAllRigh}>
              @2022 PORTFOLIO STRATEGIES. All Rights Reserved.
            </div>
            <div className={styles.privacyPolicyTermsAndCon}>
              <div  onClick={onPrivacyPolicyTextClick}  className={styles.privacyPolicyDiv}>
                Privacy Policy
              </div>
              {'   '} | {'   '} <div
              className={styles.privacyPolicyDiv}
              onClick={onTermsAndConditionClick}
            > Terms and Condition</div>
            </div>
            <div className={styles.madeWithByDigitalArtist}>
              <span className={styles.madeWithSpan}>Made with</span>
              <span className={styles.span}>{` `}</span>
              <span>
                  <span className={styles.span1}>❤️</span> by Digital Artistry
                  Marketing️
                </span>
            </div>
          </div>
        </div>
        <div className={styles.policiesDiv1}>
          <div className={styles.pORTFOLIOSTRATEGIESAllRigh1}>
            @2022 PORTFOLIO STRATEGIES. All Rights Reserved.
          </div>
          <div className={styles.privacyPolicyTermsAndCon}>
            <div  onClick={onPrivacyPolicyTextClick}  className={styles.privacyPolicyDiv}>
              Privacy Policy
            </div>
            {'   '} | {'   '} <div
            className={styles.privacyPolicyDiv}
            onClick={onTermsAndConditionClick}
          > Terms and Condition</div>
          </div>
          <div className={styles.madeWithByDigitalArtist1}>
            <span className={styles.madeWithSpan}>Made with</span>
            <span className={styles.span}>{` `}</span>
            <span>
                <span className={styles.span1}>❤️</span> by Digital Artistry
                Marketing️
              </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer;
