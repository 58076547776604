import {FunctionComponent, useState, useCallback} from "react";
import {TextareaAutosize, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MenuPS from "../components/MenuPS";
import PortalPopup from "../components/PortalPopup";
import styles from "./ContactUs.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {Parallax} from "react-parallax";

const ContactUs: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.contactUsDiv}>
        <Navbar/>
        <div className={styles.contactUsDiv1}>
          <Parallax bgImage="/img/group-936-1@2x.jpg" bgImageAlt="the cat" strength={300}
                    style={{width: "100%"}}>
            <div className={styles.contactUsDiv2}>
              <form className={styles.contactForm} action="https://submit-form.com/u2p3cc8w">
                <input
                  type="hidden"
                  name="_redirect"
                  value="https://portfolio.da2go.io/contact-us-thanks"
                  // value="http://localhost:3000/contact-us-thanks"
                />
                <div className={styles.formHeaderDiv}>
                  <div className={styles.formTitleSubtext}>MORE QUESTIONS?</div>
                  <div className={styles.formTitleDiv}>Get in Touch</div>
                </div>
                <div className={styles.formDiv}>
                  <div className={styles.formFieldsDiv}>
                    <div className={styles.destinationNameInput}>
                      <TextField
                        className={styles.inputTextField}
                        color="primary"
                        variant="outlined"
                        type="text"
                        label="Your name"
                        size="medium"
                        margin="none"
                        name="name"
                      />
                    </div>
                    <div className={styles.destinationNameInput}>
                      <TextField
                        className={styles.inputTextField}
                        color="primary"
                        variant="outlined"
                        type="text"
                        label="Email address"
                        size="medium"
                        margin="none"
                        name="email"
                      />
                    </div>
                    <div className={styles.destinationNameInput}>
                      <TextField
                        className={styles.inputTextField}
                        color="primary"
                        variant="outlined"
                        type="text"
                        label="Message"
                        size="medium"
                        minRows={6}
                        multiline={true}
                        margin="none"
                        name="message"
                      />
                    </div>
                  </div>
                  <button className={styles.button1}>
                    <div className={styles.unstyledButtonDiv}>
                      <div className={styles.buttonDiv}>SEND</div>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </Parallax>
          <Footer/>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
